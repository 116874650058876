import { Image } from 'antd';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import DefaultProfile from '~/assets/svg/default_profile.svg';
import Pagination from '~/classes/Pagination';
import { Point } from '~/classes/Point';
import { User } from '~/classes/User';
import HeaderTitleWithSorter from '~/components/HeaderTitleWithSorter';
import { Space } from '~/components/Space';
import useHeaderCell from '~/hooks/useHeaderCell';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetUsers } from '~/hooks/useUser';
import { Column, Expanded, SpaceBetween } from '~/styles/Wrappers';

const Users = () => {
  const navigate = useNavigate();

  const { sortingKeywords, onHeaderCell } = useHeaderCell();

  const columns: ColumnsType<User> = [
    {
      key: 'picture',
      title: '프로필',
      dataIndex: 'picture',
      render: (picture) => (
        <Image
          style={{ borderRadius: 50 }}
          src={picture}
          width={40}
          height={40}
          fallback={DefaultProfile}
        />
      ),
    },
    {
      key: 'reportName',
      title: (
        <HeaderTitleWithSorter
          title="리포트 이름"
          sortKey="reportName"
          sortingKeywords={sortingKeywords}
        />
      ),
      dataIndex: 'reportName',
      onHeaderCell: () => onHeaderCell('reportName'),
    },
    {
      key: 'realname',
      title: (
        <HeaderTitleWithSorter title="이름" sortKey="realname" sortingKeywords={sortingKeywords} />
      ),
      dataIndex: 'realname',
      onHeaderCell: () => onHeaderCell('realname'),
    },
    {
      key: 'email',
      title: (
        <HeaderTitleWithSorter title="이메일" sortKey="email" sortingKeywords={sortingKeywords} />
      ),
      dataIndex: 'email',
      onHeaderCell: () => onHeaderCell('email'),
    },
    { key: 'affiliation', title: '소속', dataIndex: 'affiliation' },
    {
      key: 'device',
      title: '디바이스 이름',
      dataIndex: 'device',
      render: (device) => <>{device?.deviceName || ''}</>,
    },
    {
      key: 'point',
      title: '누적 포인트',
      dataIndex: 'point',
      render: (point: Point) => <>{point?.amount.toLocaleString() || ''}</>,
    },
    {
      key: 'os/appVersion',
      title: 'os/appVersion',
      dataIndex: 'platform',
      render: (platform) => <>{platform && `${platform.OS}/${platform.appVersion}`}</>,
    },
    {
      key: 'createdAt',
      title: (
        <HeaderTitleWithSorter
          title="생성일"
          sortKey="createdAt"
          sortingKeywords={sortingKeywords}
        />
      ),
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
      onHeaderCell: () => onHeaderCell('createdAt'),
    },
    {
      key: 'updatedAt',
      title: (
        <HeaderTitleWithSorter
          title="수정일"
          sortKey="updatedAt"
          sortingKeywords={sortingKeywords}
        />
      ),
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
      onHeaderCell: () => onHeaderCell('updatedAt'),
    },
    {
      key: 'memo',
      title: '특이사항',
      dataIndex: 'memo',
      render: (memo) => <>{memo && memo.length > 10 ? `${memo.substring(0, 10)}...` : memo}</>,
    },
  ];

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));

  const { values, handleChange, handleSubmit } = useFormik<{ keyword: string }>({
    initialValues: {
      keyword: '',
    },
    validationSchema: Yup.object().shape({
      keyword: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const { data = new Pagination<User>(), refetch } = useGetUsers({
    ...pagination,
    sort: sortingKeywords,
    keyword: values.keyword,
  });

  useEffect(() => {
    refetch();
  }, [refetch, sortingKeywords]);

  useEffect(() => {
    navigate(`/users?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Expanded />
        <Search
          name="keyword"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.keyword}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ cursor: 'pointer', overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(user) => ({
          onClick: () => navigate(`/users/${user._id}`),
        })}
      />
    </Column>
  );
};

export default Users;
