export type SortOrder = -1 | 1;

export default class PaginationRequest {
  page?: number;
  limit?: number;
  sort?: [string, SortOrder][];

  constructor(paginationRequest: PaginationRequest) {
    this.page = paginationRequest.page || 1;
    this.limit = paginationRequest.limit || 10;
    this.sort = paginationRequest.sort = [];
  }
}
