import { Form, Image, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  ContentCategory,
  ContentCategoryText,
  ContentType,
  ContentTypeText,
} from '~/classes/Content';
import { ContentDto } from '~/classes/dto/ContentDto';
import { useCreateContent, useGetContent, useUpdateContent } from '~/hooks/useContent';
import useS3Handler from '~/hooks/useS3Handler';
import { Column } from '~/styles/Wrappers';

interface Props {
  contentId?: string;
  contentModalOpen: boolean;
  setContentModalOpen: (contentModalOpen: boolean) => void;
}

const ContentModal = ({ contentId, contentModalOpen, setContentModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<ContentDto>({
    initialValues: {
      _id: contentId,
      contentCategory: ContentCategory.FOCUS,
      title: '',
      contentType: ContentType.MEDITATION_GUIDE,
      image: '',
      audio: '',
    },
    validationSchema: Yup.object().shape({
      _id: Yup.string(),
      contentCategory: Yup.mixed<ContentCategory>()
        .oneOf(Object.values(ContentCategory))
        .required(),
      title: Yup.string().required(),
      contentType: Yup.mixed<ContentType>().oneOf(Object.values(ContentType)).required(),
      image: Yup.string().required(),
      audio: Yup.string().required(),
    }),
    onSubmit: async (data: ContentDto) => {
      setIsConfirmLoading(true);

      if (contentId) {
        await updateContent({ ...data, _id: contentId });
      } else {
        await createContent(data);
      }

      setIsConfirmLoading(false);
      setContentModalOpen(false);
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const { data, refetch } = useGetContent(contentId);
  const createContent = useCreateContent();
  const updateContent = useUpdateContent();

  const getFileName = useS3Handler();

  const handleS3Upload = async (event) => {
    setIsLoading(true);
    const fileName = await getFileName(event, 'content/');

    setFieldValue(event.target.name, fileName);
    setIsLoading(false);
  };

  useEffect(() => {
    if (contentId) {
      refetch();
    }
  }, [contentId, refetch]);

  useEffect(() => {
    if (data) {
      setFieldValue('contentCategory', data.contentCategory);
      setFieldValue('title', data.title);
      setFieldValue('contentType', data.contentType);
      setFieldValue('image', data.image);
      setFieldValue('audio', data.audio);
    }
  }, [data, setFieldValue]);

  useEffect(() => {
    if (!contentModalOpen) {
      resetForm();
      setIsLoading(false);
    }
  }, [contentModalOpen, resetForm]);

  return (
    <Modal
      title={contentId ? '콘텐츠 수정' : '새로운 콘텐츠 생성'}
      open={contentModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => setContentModalOpen(false)}
      destroyOnClose
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="콘텐츠 카테고리">
          <Select
            className="contentCategory"
            value={values.contentCategory}
            onChange={(contentCategory) => setFieldValue('contentCategory', contentCategory)}
            options={Object.values(ContentCategory).map((contentCategory) => ({
              value: contentCategory,
              label: ContentCategoryText[contentCategory],
            }))}
          />
        </Form.Item>

        <Form.Item label="타이틀">
          <Input name="title" value={values.title} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="콘텐츠 타입">
          <Select
            className="contentType"
            value={values.contentType}
            onChange={(contentType) => setFieldValue('contentType', contentType)}
            options={Object.values(ContentType).map((contentType) => ({
              value: contentType,
              label: ContentTypeText[contentType],
            }))}
          />
        </Form.Item>

        <Form.Item label="오디오">
          <Column>
            {values.audio && (
              <audio
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.audio}`}
                controls
              />
            )}
            <input type="file" accept="audio/*" name="audio" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="배경">
          <Column>
            {values.image && (
              <Image
                style={{ maxHeight: '300px', objectFit: 'contain' }}
                src={`${process.env.REACT_APP_S3_URL}${values.image}`}
              />
            )}
            <input type="file" accept="image/*" name="image" onChange={handleS3Upload} />
          </Column>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContentModal;
