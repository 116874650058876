import { Content } from '~/classes/Content';
import Pagination from '~/classes/Pagination';
import { ContentDto } from '~/classes/dto/ContentDto';
import { SearchContentDto } from '~/classes/dto/SearchContentDto';
import axios from '~/helper/axios';

const createContent = async (data: ContentDto): Promise<void> => {
  return await axios({ url: '/content/admin', method: 'post', data });
};

const getContent = async (contentId: string): Promise<Content> => {
  const { data: content } = await axios<Content>({
    url: `/content/${contentId}`,
    method: 'get',
  });

  return new Content(content);
};

const getContents = async (searchContentDto: SearchContentDto): Promise<Pagination<Content>> => {
  const { data: contents } = await axios<Pagination<Content>>({
    url: '/content',
    method: 'get',
    params: searchContentDto,
  });

  return new Pagination(contents, Content);
};

const updateContent = async (data: ContentDto): Promise<void> => {
  return await axios({
    url: '/content/admin',
    method: 'patch',
    data,
  });
};

const deleteContent = async (contentId: string): Promise<void> => {
  return await axios({
    url: `/content/admin/${contentId}`,
    method: 'delete',
  });
};

export default { createContent, getContent, getContents, updateContent, deleteContent };
