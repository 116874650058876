import { Subscribe } from './Subscribe';

export enum DeviceType {
  BAND = 'BAND',
}

export class Device {
  _id: string;
  deviceName: string;
  android?: string;
  ios?: string;
  iosUniqueIDs: { [key: string]: string } = {};
  deviceType: DeviceType;
  modelNumber?: string;
  firmwareRevision?: string;
  hardwareRevision?: string;
  manufacturerName?: string;
  subscribeId: string;
  subscribe?: Subscribe;
  createdAt: Date;
  updatedAt: Date;

  constructor(device: Device) {
    this._id = device._id;
    this.deviceName = device.deviceName;
    this.android = device.android;
    this.ios = device.ios;
    this.iosUniqueIDs = device.iosUniqueIDs || {};
    this.deviceType = device.deviceType;
    this.modelNumber = device.modelNumber;
    this.firmwareRevision = device.firmwareRevision;
    this.hardwareRevision = device.hardwareRevision;
    this.manufacturerName = device.manufacturerName;
    this.subscribeId = device.subscribeId;
    this.subscribe = device.subscribe && new Subscribe(device.subscribe);
    this.createdAt = new Date(device.createdAt);
    this.updatedAt = new Date(device.updatedAt);

    return this;
  }
}
