import { Doughnut } from 'react-chartjs-2';

import Color from '~/constants/Color';

interface Props {
  labels: string[];
  colors: string[];
  data: number[];
}

const DoughnutChart = ({ labels, colors, data }: Props) => {
  const doughnutData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
      },
    ],
  };

  return (
    <Doughnut
      data={doughnutData}
      options={{
        cutout: '60%',
        borderColor: Color.C232329,
        plugins: { legend: { display: false } },
      }}
    />
  );
};

export default DoughnutChart;
