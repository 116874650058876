import { Form, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { SubscribeType, SubscribeTypeText } from '~/classes/Subscribe';
import { SubscribeDto } from '~/classes/dto/SubscribeDto';
import { useCreateSubscribe } from '~/hooks/useSubscribe';

interface Props {
  deviceId: string;
  createSubscribeModalOpen: boolean;
  setCreateSubscribeModalOpen: (createSubscribeModalOpen: boolean) => void;
}

const CreateSubscribeModal = ({
  deviceId,
  createSubscribeModalOpen,
  setCreateSubscribeModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const createSubscribe = useCreateSubscribe();

  const { values, setFieldValue, handleSubmit } = useFormik<SubscribeDto>({
    initialValues: {
      deviceId,
      subscribeType: SubscribeType.HALF_YEAR,
    },
    validationSchema: Yup.object().shape({
      deviceId: Yup.string().required(),
      subscribeType: Yup.mixed<SubscribeType>().oneOf(Object.values(SubscribeType)).required(),
    }),
    onSubmit: async (data: SubscribeDto) => {
      await createSubscribe(data);

      setIsConfirmLoading(false);
      setCreateSubscribeModalOpen(false);
    },
  });

  return (
    <Modal
      title="구독 생성"
      open={createSubscribeModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setCreateSubscribeModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="디바이스 ID">
          <Input name="deviceId" value={values.deviceId} disabled />
        </Form.Item>

        <Form.Item label="구독 타입">
          <Select
            className="subscribeType"
            value={values.subscribeType}
            onChange={(subscribeType) => setFieldValue('subscribeType', subscribeType)}
            options={Object.values(SubscribeType).map((subscribeType) => ({
              value: subscribeType,
              label: SubscribeTypeText[subscribeType],
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSubscribeModal;
