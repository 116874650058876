import { produce, Draft } from 'immer';
import { useCallback } from 'react';
import { RecoilState, useSetRecoilState as useSetRecoilStateDefault } from 'recoil';

type DraftFunction<T> = (draft: Draft<T>) => void;

const useSetRecoilState = <T>(atom: RecoilState<T>) => {
  const setState = useSetRecoilStateDefault(atom);

  return useCallback(
    (valOrUpdater: T | DraftFunction<T>) =>
      setState(
        typeof valOrUpdater === 'function'
          ? produce(valOrUpdater as DraftFunction<T>)
          : (valOrUpdater as T),
      ),
    [setState],
  );
};

export default useSetRecoilState;
