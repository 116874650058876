import { Device } from './Device';
import { Point } from './Point';

export enum Role {
  ADMIN = 'ADMIN',
  COACH = 'COACH',
}

export enum Provider {
  KAKAO = 'KAKAO',
  APPLE = 'APPLE',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
}

export enum Affiliation {
  MIDDLE_SCHOOL = '중학생',
  HIGH_SCHOOL = '고등학생',
  UNIVERSITY = '대학생',
  REPEATER = '재수생',
  EXAMINEE = '고시생',
  ETC = '기타',
}

export class User {
  _id: string;
  email: string;
  reportName: string;
  picture: string;
  affiliation: Affiliation;
  birthday: string;
  gender: Gender;
  affiliationName?: string;
  affiliationId?: number;
  grade?: number;
  message?: string;
  providerIds: string[];
  providers: Provider[];
  roles: Role[];
  isPrivate: boolean;
  systemPush: boolean;
  deviceId?: string;
  device?: Device;
  point?: Point;
  pushToken?: string;
  realname?: string;
  memo?: string;
  refundUserName?: string;
  refundUserPhone?: string;
  platform: {
    OS: string;
    appVersion: string;
  };

  createdAt: Date;
  updatedAt: Date;

  constructor(user: User) {
    this._id = user._id;
    this.email = user.email;
    this.reportName = user.reportName || '';
    this.picture = user.picture;
    this.affiliation = user.affiliation;
    this.birthday = user.birthday;
    this.gender = user.gender;
    this.affiliationName = user.affiliationName;
    this.affiliationId = user.affiliationId;
    this.grade = user.grade;
    this.message = user.message;
    this.providerIds = user.providerIds;
    this.providers = user.providers;
    this.roles = user.roles;
    this.isPrivate = user.isPrivate;
    this.systemPush = user.systemPush || false; // temp default value;
    this.deviceId = user.deviceId;
    this.device = user.device && new Device(user.device);
    this.point = user.point && new Point(user.point);
    this.pushToken = user.pushToken;
    this.realname = user.realname;
    this.memo = user.memo;
    this.refundUserName = user.refundUserName;
    this.refundUserPhone = user.refundUserPhone;
    this.platform = user.platform;
    this.createdAt = new Date(user.createdAt);
    this.updatedAt = new Date(user.updatedAt);
  }
}
