import { addDays, isAfter, isSameDay } from 'date-fns';
import { toast } from 'react-toastify';

import { SortOrder } from '~/classes/PaginationRequest';
import { Token } from '~/classes/Token';
import { User } from '~/classes/User';
import Color from '~/constants/Color';

export const isSignIn = () => {
  Token.instance.loadTokenFromStorage();

  return !!Token.instance.accessToken;
};

export const convertMsToMin = (ms: number) => {
  return Math.floor(ms / 60);
};

export const getRecentUsers = (users: User[]) => {
  const pastDate = addDays(new Date(), -7);

  let count = 0;

  users.forEach((user) => {
    if (isAfter(user.createdAt, pastDate)) {
      count++;
    }
  });

  return count;
};

export const getTodayUsers = (users: User[]) => {
  let count = 0;

  users.forEach((user) => {
    if (isSameDay(user.createdAt, new Date())) {
      count++;
    }
  });

  return count;
};

export const getDecodedFileName = (url: string) => {
  return decodeURIComponent(url.split('?')[0].split(`${process.env.REACT_APP_S3_URL}`)[1]);
};

export const getKeyFromUrl = (url: string) => {
  const index = url.indexOf('?');

  return url.substring(0, index);
};

export const secondsToFormattedTime = (seconds: number, withZero: boolean = true) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (!withZero) {
    let result = h === 0 ? '' : `${h}시간`;
    if (m > 0) result += ` ${m}분`;

    if (h === 0 && m === 0 && seconds !== 0) return `${seconds}초`;

    return result.trim();
  }

  return h === 0 ? `${m}분` : `${h}시간 ${m}분`;
};

export const getEfficiencyPercentages = (efficiencyList: number[]) => {
  const efficiencySum = efficiencyList.reduce((acc, cur) => acc + cur, 0);

  if (efficiencySum === 0) {
    return [0, 0, 0, 0];
  }

  return efficiencyList.map((efficiency) => Math.round((efficiency / efficiencySum) * 100));
};

export const getTimeline = (seconds: number) => {
  const timeline = ['시작'];

  if (seconds < 60) {
    timeline.push(secondsToString(seconds / 3, '분 ', false, false, 1));
  } else {
    const length = 4;
    const each = Math.floor(seconds / length);

    for (let i = 1; i < length; i++) {
      timeline.push(secondsToString(each * i, '분 ', false, false, 1));
    }
  }

  timeline.push(secondsToString(seconds, '분 ', false, false, 1));

  return timeline;
};

export const secondsToString = (
  seconds: number,
  separator: string = ':',
  withSec = true,
  withHour = true,
  padLength = 2,
): string => {
  if (!seconds) return '-';

  const hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);

  let result = '';

  if (withHour) {
    result += `${hour.toString().padStart(padLength, '0')}${separator}`;
  } else {
    min += hour * 60;
  }
  result += `${min.toString().padStart(padLength, '0')}${separator}`;
  if (withSec) result += `${sec.toString().padStart(padLength, '0')}`;

  return result;
};

export const getSortIconColor = (
  keyword: string,
  sortDirection: number,
  sortingKeywords: [string, SortOrder][],
) => {
  const sortingKeyword = sortingKeywords.find((sortingKeyword) => sortingKeyword[0] === keyword);

  return sortingKeyword && sortingKeyword[1] === sortDirection ? Color.C5E4FFF : Color.C606276;
};

export const getNewSortingKeywords = (
  keyword: string,
  sortingKeywords: [string, SortOrder][],
): [string, SortOrder][] => {
  const sortingKeyword = sortingKeywords.find((sortingKeyword) => sortingKeyword[0] === keyword);

  if (sortingKeyword === undefined) {
    return [...sortingKeywords, [keyword, 1]];
  } else if (sortingKeyword[1] === 1) {
    return sortingKeywords.map((sortingKeyword) =>
      sortingKeyword[0] === keyword ? [keyword, -1] : sortingKeyword,
    );
  } else {
    return sortingKeywords.filter((sortingKeyword) => sortingKeyword[0] !== keyword);
  }
};

export const handleCopyClipBoard = (text: string) => {
  // 1. 임시 textarea 요소를 생성하고 body에 부착
  const $textarea = document.createElement('textarea');
  document.body.appendChild($textarea);
  // 2. props로 받은 text값을 textarea의 value로 대입하고 textarea 영역 내 모든 텍스트를 선택(드래그효과)
  $textarea.value = text;
  $textarea.select();
  // 3. execCommand 함수를 이용해 클립보드에 복사
  document.execCommand('copy');
  // 4. 임시 textarea 요소 제거
  document.body.removeChild($textarea);
  toast.success('복사되었습니다.');
};
