export default {
  C101011: '#101011',
  C232329: '#232329',
  C01F0C8: '#01F0C8',
  C01F0C880: '#01F0C880',
  C606276: '#606276',
  C151626: '#151626',
  C585BE1: '#585BE1',
  C272946: '#272946',
  C9BFDA1: '#9BFDA1',
  C5E4FFF: '#5E4FFF',
  CE97070: '#E97070',
  C4EA5B8: '#4EA5B8',
  C4760A0: '#4760A0',
  C59518D: '#59518D',
  CF297A2: '#F297A2',
  CEFEFEF: '#EFEFEF',
  CDFE5F8: '#DFE5F8',
  C3C4048: '#3C4048',
  C4D5484: '#4D5484',
  CA5A5A5: '#A5A5A5',
  CC5C5C5: '#C5C5C5',
  CFFFFFF: '#FFFFFF',
};
