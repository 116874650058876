import styled from '@emotion/styled';

import Color from '~/constants/Color';

interface FlexProps {
  flex?: number;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Center = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const SpaceBetween = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SpaceAround = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const Expanded = styled(Flex)`
  flex: ${({ flex = 1 }) => flex};
`;

export const RadiusContainer = styled(Flex)<{
  padding?: number;
  backgroundColor?: string;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
}>`
  ${({ padding = 12 }) => `padding: ${padding}px;`}
  ${({ backgroundColor = Color.CFFFFFF }) => `background-color: ${backgroundColor};`}
  ${({ borderRadius = 8 }) => `border-radius: ${borderRadius}px;`}
  ${({ borderWidth }) => borderWidth && `border-width: ${borderWidth}px;`}
  ${({ borderColor }) => borderColor && `border-color: ${borderColor};`}
`;
