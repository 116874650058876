import { format, startOfDay } from 'date-fns';

import { User } from './User';

export enum PointLogType {
  USER_JOIN = 'USER_JOIN', // 회원가입 보상
  ROUTINE = 'ROUTINE', // 습관 보상
  LEARNING = 'LEARNING', // 순공 보상
  USER_SPEND = 'USER_SPEND', // 사용자 리워드 신청
  USER_SPEND_COMPLETE = 'USER_SPEND_COMPLETE', // 사용자 리워드 지급 완료
  EVENT = 'EVENT',
  RANKING_REWARD = 'RANKING_REWARD',
  LEARNING_10HOURS_REWARD = 'LEARNING_10HOURS_REWARD',
}

export enum PointLogTypeLabel {
  USER_JOIN = '회원가입 보상', // 회원가입 보상
  ROUTINE = '습관 보상', // 습관 보상
  LEARNING = '순공 보상', // 순공 보상
  USER_SPEND = '환급 신청', // 사용자 리워드 신청
  USER_SPEND_COMPLETE = '환급 완료', // 사용자 리워드 지급 완료
  EVENT = '이벤트',
  RANKING_REWARD = '랭킹 보상',
  LEARNING_10HOURS_REWARD = '친구 초대 보상',
}

export class PointLog {
  _id: string;
  userId: string;
  user?: User;
  fromUserId: string;
  fromUser?: User;
  amount: number;
  level: number;
  currentAmount: number;
  routineStep: number;
  consecutiveDays: number;
  pointLogType: PointLogType;
  fromObjectId: string;
  fromSchema: string;
  time: number;
  memo: string;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  startOfDate: string;

  getTitle() {
    switch (this.pointLogType) {
      case PointLogType.USER_SPEND:
      case PointLogType.USER_SPEND_COMPLETE:
        return `${PointLogTypeLabel[this.pointLogType]} ${this.amount} P`;
      default:
        return `${PointLogTypeLabel[this.pointLogType]} +${this.amount} P`;
    }
  }

  constructor(pointLog: PointLog) {
    this._id = pointLog._id;
    this.userId = pointLog.userId;
    this.user = pointLog.user && new User(pointLog.user);
    this.fromUserId = pointLog.fromUserId;
    this.fromUser = pointLog.fromUser && new User(pointLog.fromUser);
    this.amount = pointLog.amount;
    this.level = pointLog.level;
    this.currentAmount = pointLog.currentAmount;
    this.routineStep = pointLog.routineStep;
    this.consecutiveDays = pointLog.consecutiveDays;
    this.pointLogType = pointLog.pointLogType;
    this.fromObjectId = pointLog.fromObjectId;
    this.fromSchema = pointLog.fromSchema;
    this.time = pointLog.time;
    this.memo = pointLog.memo;
    this.phone = pointLog.phone;
    this.createdAt = new Date(pointLog.createdAt);
    this.updatedAt = new Date(pointLog.updatedAt);
    this.startOfDate = format(startOfDay(this.createdAt), 'yyyy년 M월 d일');
  }
}
