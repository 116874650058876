import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import AuthApi from '~/apis/AuthApi';
import { Token } from '~/classes/Token';
import { Role, User } from '~/classes/User';
import authState, { initialState } from '~/recoil/auth/atom';

import useSetRecoilState from './recoilApis/useSetRecoilState';

const useLoad = () => {
  const setAuth = useSetRecoilState(authState);

  const query = useQuery({
    queryKey: ['load'],
    queryFn: AuthApi.load,
    retry: false,
    enabled: false,
  });

  const { data, refetch, isSuccess, isError } = query;

  useEffect(() => {
    if (isSuccess) {
      const { data: user } = data;

      const newUser = new User(user);

      if (!user.roles.includes(Role.ADMIN)) {
        toast.error('관리자 권한이 없습니다!');
      } else {
        setAuth((draft) => {
          draft.isLoading = false;
          draft.isSignIn = true;
          draft.me = newUser;
        });
      }
    }
  }, [data, isSuccess, setAuth]);

  useEffect(() => {
    if (isError) {
      setAuth((draft) => {
        draft.isLoading = false;
        draft.isSignIn = false;
        draft.me = initialState.me;
      });
    }
  }, [isError, setAuth]);

  useEffect(() => {
    const loadToken = async () => {
      await Token.instance.loadTokenFromStorage();

      if (Token.instance.accessToken === '') {
        return setAuth((draft) => {
          draft.isSignIn = false;
          draft.me = initialState.me;
        });
      }

      refetch();
    };

    loadToken();
  }, [refetch, setAuth]);
};

export default useLoad;
