import { FocusTimer } from '~/classes/FocusTimer';
import Pagination from '~/classes/Pagination';
import { SearchFocusTimerDto } from '~/classes/dto/FocusTimerDto';
import axios from '~/helper/axios';

const getFocusTimers = async (params: SearchFocusTimerDto): Promise<Pagination<FocusTimer>> => {
  const { data: focusTimers } = await axios<Pagination<FocusTimer>>({
    url: '/focus-timer/focus-timers',
    method: 'get',
    params,
  });

  return new Pagination(focusTimers, FocusTimer);
};

export default { getFocusTimers };
