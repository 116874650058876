import styled from '@emotion/styled';

import Color from '~/constants/Color';
import { Text8 } from '~/styles/Texts';
import { Row } from '~/styles/Wrappers';

const LabelWrapper = styled(Row)<{ backgroundColor?: string }>`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 12px;
  padding: 4px 8px;
  background-color: ${({ backgroundColor = Color.C101011 }) => backgroundColor};
`;

const LabelItem = styled(Row)`
  align-items: center;
  margin: 0 2px;
`;

const Dot = styled.div<{ backgroundColor: string }>`
  width: 4px;
  height: 4px;
  border-radius: 50px;
  margin-right: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

interface Props {
  labels: string[];
  colors: string[];
  data?: number[];
  backgroundColor?: string;
}

const Label = ({ labels, colors, data, backgroundColor }: Props) => {
  return (
    <LabelWrapper backgroundColor={backgroundColor}>
      {labels.map((label, index) => (
        <LabelItem key={label}>
          <Dot backgroundColor={colors[index]} />
          <Text8 color={Color.CEFEFEF}>
            {label}
            {data && ` (${data[index]}%)`}
          </Text8>
        </LabelItem>
      ))}
    </LabelWrapper>
  );
};

export default Label;
