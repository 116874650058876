import Pagination from '~/classes/Pagination';
import { UserGoals } from '~/classes/UserGoals';
import { UserGoalsDto } from '~/classes/dto/UserGoalsDto';
import axios from '~/helper/axios';

const getUserGoals = async (userGoalsDto: UserGoalsDto): Promise<Pagination<UserGoals>> => {
  const { data } = await axios<Pagination<UserGoals>>({
    url: '/goal/report-admin',
    method: 'get',
    params: userGoalsDto,
  });

  return new Pagination(data, UserGoals);
};

export default { getUserGoals };
