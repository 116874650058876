import { Form, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProfileDto } from '~/classes/dto/ProfileDto';
import { useUpdateUserProfile } from '~/hooks/useUser';

interface Props {
  userId: string;
  realname: string;
  updateRealnameModalOpen: boolean;
  setUpdateRealnameModalOpen: (updateRealnameModalOpen: boolean) => void;
}

const UpdateRealnameModal = ({
  userId,
  realname,
  updateRealnameModalOpen,
  setUpdateRealnameModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const updateUserProfile = useUpdateUserProfile();

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik<ProfileDto>({
    initialValues: {
      userId,
      realname,
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().required(),
      realname: Yup.string(),
    }),
    onSubmit: async (data: ProfileDto) => {
      await updateUserProfile(data);

      setIsConfirmLoading(false);
      setUpdateRealnameModalOpen(false);
    },
  });

  useEffect(() => {
    setFieldValue('userId', userId);
    setFieldValue('realname', realname);
  }, [realname, setFieldValue, userId]);

  return (
    <Modal
      title="이름 수정"
      open={updateRealnameModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setUpdateRealnameModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="이름">
          <Input
            name="realname"
            value={values.realname}
            placeholder="이름을 입력하세요"
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateRealnameModal;
