import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import SubscribeApi from '~/apis/SubscribeApi';
import PaginationRequest from '~/classes/PaginationRequest';

export const useCreateSubscribe = () => {
  const { mutateAsync } = useMutation({
    mutationFn: SubscribeApi.createSubscribe,
    onError: () => {
      toast.error('이미 구독이 등록된 기기입니다!');
    },
  });

  return mutateAsync;
};

export const useGetSubscribes = (paginationRequest: PaginationRequest) => {
  const query = useQuery({
    queryKey: ['subscribes', paginationRequest],
    queryFn: () => SubscribeApi.getSubscribes(paginationRequest),
  });

  return query;
};

export const useUpdateSubscribe = () => {
  const { mutateAsync } = useMutation({
    mutationFn: SubscribeApi.updateSubscribe,
    onError: () => {
      toast.error('구독 수정에 실패했습니다!');
    },
  });

  return mutateAsync;
};

export const useDeleteSubscribe = () => {
  const { mutateAsync } = useMutation({
    mutationFn: SubscribeApi.deleteSubscribe,
    onError: () => {
      toast.error('구독 종료에 실패했습니다!');
    },
  });

  return mutateAsync;
};

export const useGetDeviceSubscribes = (deviceId: string, paginationRequest: PaginationRequest) => {
  const query = useQuery({
    queryKey: ['subscribes', deviceId, paginationRequest],
    queryFn: () => SubscribeApi.getDeviceSubscribes(deviceId, paginationRequest),
  });

  return query;
};
