import { PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/lib/table';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Device } from '~/classes/Device';
import Pagination from '~/classes/Pagination';
import { Space } from '~/components/Space';
import { useGetDevices } from '~/hooks/useDevice';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Text20Bold } from '~/styles/Texts';
import { Column, Row, SpaceBetween } from '~/styles/Wrappers';

import CreateDeviceModal from './CreateDeviceModal';

const Devices = () => {
  const navigate = useNavigate();

  const [createDeviceModalOpen, setCreateDeviceModalOpen] = useState(false);

  const { values, handleChange, handleSubmit } = useFormik<{ deviceName: string }>({
    initialValues: {
      deviceName: '',
    },
    validationSchema: Yup.object().shape({
      deviceName: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const columns: ColumnsType<Device> = [
    {
      key: 'deviceName',
      title: '디바이스 이름',
      dataIndex: 'deviceName',
    },
    {
      key: 'subscribeStartUser',
      title: '구독 유저 리포트 이름',
      dataIndex: 'subscribe',
      render: (subscribe) => <>{subscribe?.subscribeStartUser?.reportName}</>,
    },
    {
      key: 'subscribeStartUser',
      title: '구독 유저 이름',
      dataIndex: 'subscribe',
      render: (subscribe) => <>{subscribe?.subscribeStartUser?.realname}</>,
    },
    {
      key: 'subscribeStartedAt',
      title: '구독 시작일',
      dataIndex: 'subscribe',
      render: (subscribe) => <>{subscribe?.subscribeStartedAt?.toLocaleDateString()}</>,
    },
    {
      key: 'subscribeFinishedAt',
      title: '구독 종료일',
      dataIndex: 'subscribe',
      render: (subscribe) => <>{subscribe?.subscribeFinishedAt?.toLocaleDateString()}</>,
    },
    {
      key: 'modelNumber',
      title: 'ModelNumber',
      dataIndex: 'modelNumber',
    },
    {
      key: 'firmwareRevision',
      title: 'Firmware Revision',
      dataIndex: 'firmwareRevision',
    },
    {
      key: 'hardwareRevision',
      title: 'Hardware Revision',
      dataIndex: 'hardwareRevision',
    },
    {
      key: 'createdAt',
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
  ];

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));
  const { data = new Pagination<Device>(), refetch } = useGetDevices({
    ...pagination,
    deviceName: values.deviceName || undefined,
  });

  useEffect(() => {
    navigate(`/devices?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  useEffect(() => {
    refetch();
  }, [refetch, createDeviceModalOpen]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Row>
          <Text20Bold>디바이스</Text20Bold>
          <Space width={12} />

          <PlusCircleOutlined onClick={() => setCreateDeviceModalOpen(true)} />
        </Row>
        <Search
          name="deviceName"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.deviceName}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ cursor: 'pointer', overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(device) => ({
          onClick: () => navigate(`/devices/${device?._id}/subscribes`),
        })}
      />

      <CreateDeviceModal
        createDeviceModalOpen={createDeviceModalOpen}
        setCreateDeviceModalOpen={setCreateDeviceModalOpen}
      />
    </Column>
  );
};

export default Devices;
