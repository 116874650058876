import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import ContentApi from '~/apis/ContentApi';

export const useCreateContent = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ContentApi.createContent,
    onError: () => {
      toast.error('콘텐츠를 생성하는데 실패했습니다! 다시 시도해주세요.');
    },
  });

  return mutateAsync;
};

export const useGetContent = (contentId) => {
  const query = useQuery({
    queryKey: ['getContent', contentId],
    queryFn: () => ContentApi.getContent(contentId),
    enabled: false,
  });

  return query;
};

export const useGetContents = (searchContentDto) => {
  const query = useQuery({
    queryKey: ['getContents', searchContentDto],
    queryFn: () => ContentApi.getContents(searchContentDto),
  });

  return query;
};

export const useUpdateContent = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ContentApi.updateContent,
    onError: () => {
      toast.error('콘텐츠를 수정하는데 실패했습니다! 다시 시도해주세요.');
    },
  });

  return mutateAsync;
};

export const useDeleteContent = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ContentApi.deleteContent,
    onError: () => {
      toast.error('콘텐츠를 삭제하는데 실패했습니다! 다시 시도해주세요.');
    },
  });

  return mutateAsync;
};
