import { Modal } from 'antd';
import { useState } from 'react';

import { useDeleteSubscribe } from '~/hooks/useSubscribe';

interface Props {
  subscribeId: string;
  deleteSubscribeModalOpen: boolean;
  setDeleteSubscribeModalOpen: (deleteSubscribeModalOpen: boolean) => void;
}

const DeleteSubscribeModal = ({
  subscribeId,
  deleteSubscribeModalOpen,
  setDeleteSubscribeModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const deleteSubscribe = useDeleteSubscribe();

  const handleSubmit = async () => {
    await deleteSubscribe(subscribeId);

    setIsConfirmLoading(false);
    setDeleteSubscribeModalOpen(false);
  };

  return (
    <Modal
      title="정말 구독을 종료하시겠습니까?"
      open={deleteSubscribeModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setDeleteSubscribeModalOpen(false)}
    />
  );
};

export default DeleteSubscribeModal;
