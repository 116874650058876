import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { SortOrder } from '~/classes/PaginationRequest';
import { Column, SpaceBetween } from '~/styles/Wrappers';
import { getSortIconColor } from '~/utils';

interface Props {
  title: string;
  sortKey: string;
  sortingKeywords: [string, SortOrder][];
}

const HeaderTitleWithSorter = ({ title, sortKey, sortingKeywords }: Props) => {
  return (
    <SpaceBetween>
      <>{title}</>
      <Column>
        <CaretUpOutlined style={{ color: getSortIconColor(sortKey, 1, sortingKeywords) }} />
        <CaretDownOutlined style={{ color: getSortIconColor(sortKey, -1, sortingKeywords) }} />
      </Column>
    </SpaceBetween>
  );
};

export default HeaderTitleWithSorter;
