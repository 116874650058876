import { useMutation } from '@tanstack/react-query';

import S3ManagerAPI from '~/apis/S3ManagerAPI';

export const useCreateS3Url = () => {
  const { mutateAsync } = useMutation({
    mutationFn: S3ManagerAPI.createS3Url,
  });

  return mutateAsync;
};
