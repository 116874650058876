import axios from '~/helper/axios';

export class Token {
  static instance: Token = new Token();

  accessToken: string = '';
  refreshToken: string = '';

  loadTokenFromStorage() {
    const tokenString =
      localStorage.getItem('token') || JSON.stringify({ accessToken: '', refreshToken: '' });
    const token = JSON.parse(tokenString) as Token;

    this.setStorage(token);
  }

  setStorage(token: Token) {
    Token.instance.accessToken = token.accessToken;
    Token.instance.refreshToken = token.refreshToken;

    axios.defaults.headers.common.authorization = `Bearer ${token.accessToken}`;

    localStorage.setItem('token', JSON.stringify(token));
  }

  removeStorage() {
    delete axios.defaults.headers.common.authorization;

    localStorage.removeItem('token');
  }
}
