import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAc8ZRJmpguWlUodFauhgWfmC8I40hBOIY',
  authDomain: 'focusmate-2a2ef.firebaseapp.com',
  projectId: 'focusmate-2a2ef',
  storageBucket: 'focusmate-2a2ef.appspot.com',
  messagingSenderId: '397699591163',
  appId: '1:397699591163:web:7b71fc5d0495878916b95e',
  measurementId: 'G-4W4KSS7KK2',
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const googlePopup = signInWithPopup;
