import { Form, Input, Modal } from 'antd';
import { addDays, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useUpdateSubscribe } from '~/hooks/useSubscribe';

interface Props {
  subscribeId: string;
  subscribeStartedAt: Date;
  subscribeFinishedAt: Date;
  subtractSubscribeModalOpen: boolean;
  setSubtractSubscribeModalOpen: (subtractSubscribeModalOpen: boolean) => void;
}

const SubtractSubscribeModal = ({
  subscribeId,
  subscribeStartedAt,
  subscribeFinishedAt,
  subtractSubscribeModalOpen,
  setSubtractSubscribeModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const updateSubscribe = useUpdateSubscribe();

  const { values, handleChange, handleSubmit } = useFormik<{ day: number }>({
    initialValues: {
      day: 0,
    },
    validationSchema: Yup.object().shape({
      day: Yup.number().required(),
    }),
    onSubmit: async (data: { day: number }) => {
      const updatedSubscribeFinishedAt = addDays(subscribeFinishedAt, -data.day);

      if (isBefore(updatedSubscribeFinishedAt, subscribeStartedAt)) {
        return toast.warn('구독 시작일보다 더 많이 축소할 수 없습니다!');
      }

      await updateSubscribe({
        subscribeId,
        subscribeFinishedAt: updatedSubscribeFinishedAt,
      });

      setIsConfirmLoading(false);
      setSubtractSubscribeModalOpen(false);
    },
  });

  return (
    <Modal
      title="구독일 축소"
      open={subtractSubscribeModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setSubtractSubscribeModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="축소할 구독일 수">
          <Input type="number" name="day" value={values.day} onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubtractSubscribeModal;
