import Color from '~/constants/Color';

import { Goal } from './Goal';
import { User } from './User';

export enum FocusTimerState {
  STARTED = 'STARTED', // 시작
  AUTO_SAVED = 'AUTO_SAVED', // 1분 단위 중간 저장
  COMPLETED = 'COMPLETED', // 종료
}

export class FocusTimer {
  _id: string;
  userId: string;
  user?: User;
  goalId: string;
  goal?: Goal;
  time: number;
  pauseTime: number;
  recordCount: number;
  efficiency: number;
  efficiencies: number[] = [];
  efficiencyVHCount: number;
  efficiencyHCount: number;
  efficiencyMCount: number;
  efficiencyLCount: number;
  capacity: number;
  stress: number;
  interruptedCount: number; // 람다에서 분석한 인터럽트 수
  interruptedCountInApp: number; // 앱에서 발생한 인터럽트 수
  pauseCount: number; // 앱에서 체크한 일시정지 수
  focusTimerState: FocusTimerState;
  withBand: boolean;
  feedback1?: string;
  feedback2?: string;
  feedback3?: string;
  assessment: string;
  diaryImages: string[] = [];
  startedAt: Date;
  completedAt?: Date;
  updatedAt: Date;
  autosaveExpiredAt?: Date;

  getGoalColor() {
    if (this.feedback1 === '초과달성') {
      return Color.C9BFDA1;
    } else if (this.feedback1 === '달성') {
      return Color.C01F0C8;
    } else {
      return Color.CE97070;
    }
  }

  isExpired() {
    if (
      this.focusTimerState === FocusTimerState.AUTO_SAVED &&
      this.autosaveExpiredAt! <= new Date()
    ) {
      return true;
    }

    return false;
  }

  constructor(focusTimer: FocusTimer) {
    this._id = focusTimer._id;
    this.userId = focusTimer.userId;
    this.user = focusTimer.user && new User(focusTimer.user);
    this.goalId = focusTimer.goalId;
    this.goal = focusTimer.goal && new Goal(focusTimer.goal);
    this.time = focusTimer.time;
    this.pauseTime = focusTimer.pauseTime;
    this.recordCount = focusTimer.recordCount;
    this.efficiency = Math.floor(focusTimer.efficiency);
    this.efficiencies = focusTimer.efficiencies;
    this.efficiencyVHCount = focusTimer.efficiencyVHCount;
    this.efficiencyHCount = focusTimer.efficiencyHCount;
    this.efficiencyMCount = focusTimer.efficiencyMCount;
    this.efficiencyLCount = focusTimer.efficiencyLCount;
    this.capacity = Math.floor(focusTimer.capacity);
    this.stress = Math.floor(focusTimer.stress);
    this.interruptedCount = focusTimer.interruptedCount;
    this.interruptedCountInApp = focusTimer.interruptedCountInApp;
    this.pauseCount = focusTimer.pauseCount;
    this.focusTimerState = focusTimer.focusTimerState;
    this.withBand = focusTimer.withBand;
    this.feedback1 = focusTimer.feedback1;
    this.feedback2 = focusTimer.feedback2;
    this.feedback3 = focusTimer.feedback3;
    this.assessment = focusTimer.assessment || '';
    this.diaryImages = focusTimer.diaryImages;
    this.startedAt = new Date(focusTimer.startedAt);
    this.completedAt = focusTimer.completedAt && new Date(focusTimer.completedAt);
    this.updatedAt = new Date(focusTimer.updatedAt);
    this.autosaveExpiredAt = focusTimer.autosaveExpiredAt && new Date(focusTimer.autosaveExpiredAt);
  }
}
