import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Pagination from '~/classes/Pagination';
import {
  Subscribe,
  SubscribeState,
  SubscribeStateText,
  SubscribeTypeText,
} from '~/classes/Subscribe';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetDeviceSubscribes } from '~/hooks/useSubscribe';
import { Text20Bold } from '~/styles/Texts';
import { Column, Expanded, SpaceBetween } from '~/styles/Wrappers';

import AddSubscribeModal from './AddSubscribeModal';
import CreateSubscribeModal from './CreateSubscribeModal';
import DeleteSubscribeModal from './DeleteSubscribeModal';
import SubtractSubscribeModal from './SubtractSubscribeModal';

const DeviceSubscribes = () => {
  const navigate = useNavigate();

  const [subscribeId, setSubscribeId] = useState('');
  const [subscribeStartedAt, setSubscribeStartedAt] = useState<Date | undefined>(undefined);
  const [subscribeFinishedAt, setSubscribeFinishedAt] = useState<Date | undefined>(undefined);
  const [createSubscribeModalOpen, setCreateSubscribeModalOpen] = useState(false);
  const [addSubscribeModalOpen, setAddSubscribeModalOpen] = useState(false);
  const [subtractSubscribeModalOpen, setSubtractSubscribeModalOpen] = useState(false);
  const [deleteSubscribeModalOpen, setDeleteSubscribeModalOpen] = useState(false);

  const columns: ColumnsType<Subscribe> = [
    {
      key: 'device',
      title: '구독 상태',
      render: (_, subscribe) => <>{SubscribeStateText[subscribe.subscribeState]}</>,
    },
    {
      key: 'device',
      title: 'LX 등록날짜',
      render: (_, subscribe) => <>{subscribe.createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'device',
      title: '구독 상태',
      render: (_, subscribe) => <>{SubscribeTypeText[subscribe.subscribeType]}</>,
    },
    {
      title: '구독 고객 정보',
      children: [
        {
          key: 'subscribeStartUser',
          title: '리포트 이름',
          dataIndex: 'subscribeStartUser',
          render: (subscribeStartUser) => <>{subscribeStartUser?.reportName || ''}</>,
        },
        {
          key: 'subscribeStartUser',
          title: '이름',
          dataIndex: 'subscribeStartUser',
          render: (subscribeStartUser) => <>{subscribeStartUser?.realname || ''}</>,
        },

        {
          key: 'subscribeStartUser',
          title: '이메일',
          dataIndex: 'subscribeStartUser',
          render: (subscribeStartUser) => <>{subscribeStartUser?.email || ''}</>,
        },
      ],
    },
    {
      title: '구독 정보',
      children: [
        {
          key: 'subscribeStartedAt',
          title: '구독 시작일',
          dataIndex: 'subscribeStartedAt',
          render: (subscribeStartedAt) => (
            <>{subscribeStartedAt ? subscribeStartedAt.toLocaleDateString() : ''}</>
          ),
        },
        {
          key: 'subscribeFinishedAt',
          title: '구독 종료일',
          dataIndex: 'subscribeFinishedAt',
          render: (subscribeFinishedAt) => (
            <>{subscribeFinishedAt ? subscribeFinishedAt.toLocaleDateString() : ''}</>
          ),
        },
      ],
    },
    {
      key: '_id',
      title: '구독일 추가 / 축소',
      dataIndex: '_id',
      render: (_id, subscribe) => (
        <>
          <Button
            onClick={() => {
              setSubscribeId(_id);
              setSubscribeFinishedAt(subscribe.subscribeFinishedAt);
              setAddSubscribeModalOpen(true);
            }}
            disabled={!subscribe.subscribeFinishedAt}
          >
            추가
          </Button>
          <Button
            onClick={() => {
              setSubscribeId(_id);
              setSubscribeStartedAt(subscribe.subscribeStartedAt);
              setSubscribeFinishedAt(subscribe.subscribeFinishedAt);
              setSubtractSubscribeModalOpen(true);
            }}
            disabled={!subscribe.subscribeFinishedAt}
          >
            축소
          </Button>
        </>
      ),
    },
    {
      key: '_id',
      title: '구독 종료',
      dataIndex: '_id',
      render: (_id, subscribe) => (
        <Button
          onClick={() => {
            setSubscribeId(_id);
            setDeleteSubscribeModalOpen(true);
          }}
          disabled={!(subscribe.subscribeState === SubscribeState.SUBSCRIBING)}
        >
          종료
        </Button>
      ),
    },
  ];

  const { deviceId = '' } = useParams();

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));
  const { data = new Pagination<Subscribe>(), refetch } = useGetDeviceSubscribes(
    deviceId,
    pagination,
  );

  useEffect(() => {
    navigate(`/devices/${deviceId}/subscribes?page=${pagination.page}&limit=${pagination.limit}`, {
      replace: true,
    });
  }, [pagination.page, pagination.limit, navigate, deviceId]);

  useEffect(() => {
    refetch();
  }, [
    refetch,
    createSubscribeModalOpen,
    addSubscribeModalOpen,
    subtractSubscribeModalOpen,
    deleteSubscribeModalOpen,
  ]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Row>
          <Text20Bold>구독 {data.data[0]?.device?.deviceName}</Text20Bold>
          <Space width={12} />

          <PlusCircleOutlined onClick={() => setCreateSubscribeModalOpen(true)} />
        </Row>
        <Expanded />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <CreateSubscribeModal
        deviceId={deviceId}
        createSubscribeModalOpen={createSubscribeModalOpen}
        setCreateSubscribeModalOpen={setCreateSubscribeModalOpen}
      />

      <AddSubscribeModal
        subscribeId={subscribeId}
        subscribeFinishedAt={subscribeFinishedAt!}
        addSubscribeModalOpen={addSubscribeModalOpen}
        setAddSubscribeModalOpen={setAddSubscribeModalOpen}
      />

      <SubtractSubscribeModal
        subscribeId={subscribeId}
        subscribeStartedAt={subscribeStartedAt!}
        subscribeFinishedAt={subscribeFinishedAt!}
        subtractSubscribeModalOpen={subtractSubscribeModalOpen}
        setSubtractSubscribeModalOpen={setSubtractSubscribeModalOpen}
      />

      <DeleteSubscribeModal
        subscribeId={subscribeId}
        deleteSubscribeModalOpen={deleteSubscribeModalOpen}
        setDeleteSubscribeModalOpen={setDeleteSubscribeModalOpen}
      />
    </Column>
  );
};

export default DeviceSubscribes;
