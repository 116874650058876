export default {
  // without authentication
  SIGNIN: '/signIn',

  // with authentication
  DASHBOARD: '/',
  USERS: '/users',
  USER_DETAIL: '/users/:userId',
  FOCUS_TIMERS: '/focus-timers',
  DEVICES: '/devices',
  SUBSCRIBES: '/subscribes',
  DEVICE_SUBSCRIBES: '/devices/:deviceId/subscribes',
  POINTS: '/points',
  REFUNDS: '/refunds',
  CONTENTS: '/contents',
};
