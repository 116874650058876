import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Pagination from '~/classes/Pagination';
import { Subscribe, SubscribeState } from '~/classes/Subscribe';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetSubscribes } from '~/hooks/useSubscribe';
import { Column } from '~/styles/Wrappers';

import AddSubscribeModal from '../DeviceSubscribes/AddSubscribeModal';
import DeleteSubscribeModal from '../DeviceSubscribes/DeleteSubscribeModal';
import SubtractSubscribeModal from '../DeviceSubscribes/SubtractSubscribeModal';

const Subscribes = () => {
  const navigate = useNavigate();

  const [subscribeId, setSubscribeId] = useState('');
  const [subscribeStartedAt, setSubscribeStartedAt] = useState<Date | undefined>(undefined);
  const [subscribeFinishedAt, setSubscribeFinishedAt] = useState<Date | undefined>(undefined);
  const [addSubscribeModalOpen, setAddSubscribeModalOpen] = useState(false);
  const [subtractSubscribeModalOpen, setSubtractSubscribeModalOpen] = useState(false);
  const [deleteSubscribeModalOpen, setDeleteSubscribeModalOpen] = useState(false);

  const columns: ColumnsType<Subscribe> = [
    {
      key: 'device',
      title: '배송 메모',
      dataIndex: 'device',
      render: (device) => <>{device?.deviceName || ''}</>,
    },
    {
      title: '등록자',
      children: [
        {
          key: 'subscribeStartUser',
          title: '리포트 이름',
          dataIndex: 'subscribeStartUser',
          render: (subscribeStartUser) => <>{subscribeStartUser?.reportName || ''}</>,
        },
        {
          key: 'subscribeStartUser',
          title: '이메일',
          dataIndex: 'subscribeStartUser',
          render: (subscribeStartUser) => <>{subscribeStartUser?.email || ''}</>,
        },
      ],
    },
    {
      title: '등록 정보',
      children: [
        {
          key: 'device',
          title: '등록 시리얼 번호',
          dataIndex: 'device',
          render: (device) => <>{device?.deviceName || ''}</>,
        },
        {
          key: 'device',
          title: '시각',
          dataIndex: 'device',
          render: (device) => <>{device?.createdAt.toLocaleDateString() || ''}</>,
        },
      ],
    },
    {
      title: '구독 정보',
      children: [
        {
          key: 'subscribeStartedAt',
          title: '구독 시작일',
          dataIndex: 'subscribeStartedAt',
          render: (subscribeStartedAt) => (
            <>{subscribeStartedAt ? subscribeStartedAt.toLocaleDateString() : ''}</>
          ),
        },
        {
          key: 'subscribeFinishedAt',
          title: '구독 종료일',
          dataIndex: 'subscribeFinishedAt',
          render: (subscribeFinishedAt) => (
            <>{subscribeFinishedAt ? subscribeFinishedAt.toLocaleDateString() : ''}</>
          ),
        },
      ],
    },
    {
      key: '_id',
      title: '구독일 추가 / 축소',
      dataIndex: '_id',
      render: (_id, subscribe) => (
        <>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              setSubscribeId(_id);
              setSubscribeFinishedAt(subscribe.subscribeFinishedAt);
              setAddSubscribeModalOpen(true);
            }}
            disabled={!subscribe?.subscribeFinishedAt}
          >
            추가
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              setSubscribeId(_id);
              setSubscribeStartedAt(subscribe.subscribeStartedAt);
              setSubscribeFinishedAt(subscribe.subscribeFinishedAt);
              setSubtractSubscribeModalOpen(true);
            }}
            disabled={!subscribe.subscribeFinishedAt}
          >
            축소
          </Button>
        </>
      ),
    },
    {
      key: '_id',
      title: '구독 종료',
      dataIndex: '_id',
      render: (_id, subscribe) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();

            setSubscribeId(_id);
            setDeleteSubscribeModalOpen(true);
          }}
          disabled={!(subscribe.subscribeState === SubscribeState.SUBSCRIBING)}
        >
          종료
        </Button>
      ),
    },
  ];

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));
  const { data = new Pagination<Subscribe>(), refetch } = useGetSubscribes(pagination);

  useEffect(() => {
    navigate(`/subscribes?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  useEffect(() => {
    refetch();
  }, [refetch, addSubscribeModalOpen, subtractSubscribeModalOpen, deleteSubscribeModalOpen]);

  return (
    <Column flex={1}>
      <Table
        style={{ overflow: 'scroll', cursor: 'pointer' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(subscribe) => ({
          onClick: () => navigate(`/devices/${subscribe.deviceId}/subscribes`),
        })}
      />

      <AddSubscribeModal
        subscribeId={subscribeId}
        subscribeFinishedAt={subscribeFinishedAt!}
        addSubscribeModalOpen={addSubscribeModalOpen}
        setAddSubscribeModalOpen={setAddSubscribeModalOpen}
      />

      <SubtractSubscribeModal
        subscribeId={subscribeId}
        subscribeStartedAt={subscribeStartedAt!}
        subscribeFinishedAt={subscribeFinishedAt!}
        subtractSubscribeModalOpen={subtractSubscribeModalOpen}
        setSubtractSubscribeModalOpen={setSubtractSubscribeModalOpen}
      />

      <DeleteSubscribeModal
        subscribeId={subscribeId}
        deleteSubscribeModalOpen={deleteSubscribeModalOpen}
        setDeleteSubscribeModalOpen={setDeleteSubscribeModalOpen}
      />
    </Column>
  );
};

export default Subscribes;
