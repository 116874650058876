import { Form, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { CreateDeviceDto } from '~/classes/dto/DeviceDto';
import { useCreateDevice } from '~/hooks/useDevice';

interface Props {
  createDeviceModalOpen: boolean;
  setCreateDeviceModalOpen: (createDeviceModalOpen: boolean) => void;
}

const CreateDeviceModal = ({ createDeviceModalOpen, setCreateDeviceModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const createDevice = useCreateDevice();

  const { values, handleChange, handleSubmit } = useFormik<CreateDeviceDto>({
    initialValues: {
      deviceName: '',
    },
    validationSchema: Yup.object().shape({
      deviceName: Yup.string().required(),
    }),
    onSubmit: async (data: CreateDeviceDto) => {
      await createDevice(data);

      setIsConfirmLoading(false);
      setCreateDeviceModalOpen(false);
    },
  });

  return (
    <Modal
      title="디바이스 생성"
      open={createDeviceModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setCreateDeviceModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="디바이스 이름">
          <Input
            name="deviceName"
            value={values.deviceName}
            placeholder="LXB-00000000"
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateDeviceModal;
