import { User } from './User';

export class Point {
  _id: string;
  userId: string;
  user?: User;
  amount: number;
  refundAmount: number;
  routineStep: number;
  consecutiveDays: number;
  lastPlayDate: string;
  shieldCount: number;
  last30DaysPlayCount?: number;
  createdAt: Date;
  updatedAt: Date;

  getRoutineLevel() {
    if (this.routineStep < 8) return 1;
    else if (this.routineStep < 15) return 2;
    else if (this.routineStep < 22) return 3;
    else return 4;
  }

  getRoutineCircleCount() {
    const level = this.getRoutineLevel();

    if (level === 1) return this.routineStep;
    else if (level === 2) return this.routineStep - 7;
    else if (level === 3) return this.routineStep - 14;
    else return this.routineStep - 21;
  }

  getLearningLevel(time: number) {
    const thresholds = [
      { level: 1, nextLevelThresholdTime: 30 * 60 },
      { level: 2, nextLevelThresholdTime: 60 * 60 },
      { level: 3, nextLevelThresholdTime: 90 * 60 },
      { level: 4, nextLevelThresholdTime: 120 * 60 },
      { level: 5, nextLevelThresholdTime: Number.MAX_SAFE_INTEGER },
    ];

    const threshold = thresholds.find((threshold) => threshold.nextLevelThresholdTime > time)!;

    return threshold.level;
  }

  getLearningNextLevelThresholdTime(time: number) {
    const thresholds = [
      { level: 1, nextLevelThresholdTime: 30 * 60 },
      { level: 2, nextLevelThresholdTime: 60 * 60 },
      { level: 3, nextLevelThresholdTime: 90 * 60 },
      { level: 4, nextLevelThresholdTime: 120 * 60 },
      { level: 5, nextLevelThresholdTime: Number.MAX_SAFE_INTEGER },
    ];

    const threshold = thresholds.find((threshold) => threshold.nextLevelThresholdTime > time)!;

    return threshold.nextLevelThresholdTime;
  }

  constructor(point: Point) {
    this._id = point._id;
    this.userId = point.userId;
    this.user = point.user && new User(point.user);
    this.amount = point.amount;
    this.refundAmount = point.refundAmount || 0;
    this.routineStep = point.routineStep;
    this.consecutiveDays = point.consecutiveDays;
    this.lastPlayDate = point.lastPlayDate;
    this.shieldCount = point.shieldCount;
    this.last30DaysPlayCount = point.last30DaysPlayCount || 0;
    this.createdAt = new Date(point.createdAt);
    this.updatedAt = new Date(point.updatedAt);
  }
}
