import { Button, Image } from 'antd';

import FocusmateRow from '~/assets/svg/foucsmate_row.svg';
import Looxidlabs from '~/assets/svg/looxidlabs.svg';
import { Space } from '~/components/Space';
import Color from '~/constants/Color';
import useSignIn from '~/hooks/useSignIn';
import { Center } from '~/styles/Wrappers';

const Signin = () => {
  const { signInWithGoogle } = useSignIn();

  return (
    <Center flex={1} backgroundColor={Color.C101011}>
      <Image src={FocusmateRow} width={400} />
      <Space height={40} />

      <Button onClick={signInWithGoogle}>Google로 로그인</Button>
      <Space height={120} />

      <Image src={Looxidlabs} width={100} />
    </Center>
  );
};

export default Signin;
