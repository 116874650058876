import { Image, Button } from 'antd';
import { Header as HeaderDefault } from 'antd/es/layout/layout';

import FocusmateRow from '~/assets/svg/foucsmate_row.svg';
import Color from '~/constants/Color';
import useSignIn from '~/hooks/useSignIn';

const Header = () => {
  const { signOut } = useSignIn();

  return (
    <HeaderDefault
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '10%',
        backgroundColor: Color.C101011,
      }}
    >
      <Image src={FocusmateRow} width={200} />
      <Button onClick={signOut}>로그아웃</Button>
    </HeaderDefault>
  );
};

export default Header;
