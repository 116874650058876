import { Table, Tag, Image } from 'antd';
import { ColumnsType } from 'antd/es/table';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DefaultProfile from '~/assets/svg/default_profile.svg';
import Pagination from '~/classes/Pagination';
import { PointLog, PointLogType } from '~/classes/PointLog';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetRefunds } from '~/hooks/useRefund';
import { Column } from '~/styles/Wrappers';

import UpdateRefundModal from './UpdateRefundModal';

const Refunds = () => {
  const navigate = useNavigate();

  const columns: ColumnsType<PointLog> = [
    {
      key: 'user',
      title: '프로필',
      dataIndex: 'user',
      render: (user) => (
        <Image
          style={{ borderRadius: 50 }}
          src={user?.picture}
          width={40}
          height={40}
          fallback={DefaultProfile}
        />
      ),
    },
    {
      key: 'user',
      title: '환급 이름',
      dataIndex: 'user',
      render: (user) => <>{user?.refundUserName}</>,
    },
    {
      key: 'user',
      title: ' 리포트 이름',
      dataIndex: 'user',
      render: (user) => <>{user?.reportName}</>,
    },
    {
      key: 'user',
      title: '번호',
      dataIndex: 'user',
      render: (user) => <>{user?.refundUserPhone}</>,
    },
    {
      key: 'amount',
      title: '환급 포인트',
      dataIndex: 'amount',
      render: (amount) => <>{amount.toLocaleString()}</>,
    },
    {
      key: 'currentAmount',
      title: '잔여 포인트',
      dataIndex: 'currentAmount',
      render: (currentAmount) => <>{currentAmount.toLocaleString()}</>,
    },
    {
      key: 'pointLogType',
      title: '환급 완료 여부',
      dataIndex: 'pointLogType',
      render: (pointLogType, pointLog) =>
        pointLogType === PointLogType.USER_SPEND_COMPLETE ? (
          <Tag bordered={false} color="success">
            환급 완료
          </Tag>
        ) : (
          <Tag
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setPointLogId(pointLog._id);
              setUpdateRefundModalOpen(true);
            }}
            bordered={false}
            color="processing"
          >
            환급 대기중
          </Tag>
        ),
    },
    {
      key: 'createdAt',
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
  ];

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));
  const { data = new Pagination<PointLog>(), refetch } = useGetRefunds(pagination);

  const [pointLogId, setPointLogId] = useState('');
  const [updateRefundModalOpen, setUpdateRefundModalOpen] = useState(false);

  useEffect(() => {
    navigate(`/refunds?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  useEffect(() => {
    refetch();
  }, [refetch, updateRefundModalOpen]);

  return (
    <Column flex={1}>
      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <UpdateRefundModal
        pointLogId={pointLogId}
        updateRefundModalOpen={updateRefundModalOpen}
        setUpdateRefundModalOpen={setUpdateRefundModalOpen}
      />
    </Column>
  );
};

export default Refunds;
