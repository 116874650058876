import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import { Subscribe } from '~/classes/Subscribe';
import { SubscribeDto } from '~/classes/dto/SubscribeDto';
import axios from '~/helper/axios';

const createSubscribe = async (data: SubscribeDto): Promise<void> => {
  return await axios({
    url: '/subscribe',
    method: 'post',
    data,
  });
};

const getSubscribes = async (params: PaginationRequest): Promise<Pagination<Subscribe>> => {
  const { data: subscribes } = await axios<Pagination<Subscribe>>({
    url: '/subscribe/subscribes',
    method: 'get',
    params,
  });

  return new Pagination(subscribes, Subscribe);
};

const getDeviceSubscribes = async (
  deviceId: string,
  params: PaginationRequest,
): Promise<Pagination<Subscribe>> => {
  const { data: subscribes } = await axios<Pagination<Subscribe>>({
    url: `/subscribe/subscribes/${deviceId}`,
    method: 'get',
    params,
  });

  return new Pagination(subscribes, Subscribe);
};

const updateSubscribe = async ({
  subscribeId,
  subscribeFinishedAt,
}: {
  subscribeId: string;
  subscribeFinishedAt: Date;
}): Promise<void> => {
  return await axios({
    url: `/subscribe/${subscribeId}`,
    method: 'patch',
    data: { subscribeFinishedAt },
  });
};

const deleteSubscribe = async (subscribeId: string): Promise<void> => {
  return await axios({
    url: `/subscribe/${subscribeId}/reset`,
    method: 'patch',
  });
};

export default {
  createSubscribe,
  getSubscribes,
  getDeviceSubscribes,
  updateSubscribe,
  deleteSubscribe,
};
