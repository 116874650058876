import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import AuthApi from '~/apis/AuthApi';
import { Token } from '~/classes/Token';
import { Role, User } from '~/classes/User';
import { firebaseAuth, googlePopup, googleProvider } from '~/helper/firebase';
import authState, { initialState } from '~/recoil/auth/atom';

import useSetRecoilState from './recoilApis/useSetRecoilState';

const useSignIn = () => {
  const setAuth = useSetRecoilState(authState);

  const { mutate } = useMutation({
    mutationFn: AuthApi.signIn,
    onSuccess: async (data: AxiosResponse) => {
      const { user, token } = data!.data;

      const newUser = new User(user);

      if (!user.roles.includes(Role.ADMIN)) {
        return toast.error('관리자 권한이 없습니다!');
      }

      setAuth((draft) => {
        draft.isSignIn = true;
        draft.me = newUser;
      });

      await Token.instance.setStorage(token);
    },
    onError: () => {
      setAuth((draft) => {
        draft.isSignIn = false;
        draft.me = initialState.me;
      });
    },
  });

  const signInWithGoogle = async () => {
    const userCredential = await googlePopup(firebaseAuth, googleProvider);
    const firebaseIdToken = await userCredential.user.getIdToken();

    mutate({ idToken: firebaseIdToken });
  };

  const signOut = async () => {
    await Token.instance.removeStorage();

    setAuth((draft) => {
      draft.isSignIn = false;
      draft.me = initialState.me;
    });
  };

  return { signInWithGoogle, signOut };
};

export default useSignIn;
