// https://stackoverflow.com/questions/73711544/use-immer-with-recoil
import { produce, Draft } from 'immer';
import { useCallback } from 'react';
import { RecoilState, useRecoilState as useRecoilStateDefault } from 'recoil';

type DraftFunction<T> = (draft: Draft<T>) => void;

const useRecoilState = <T>(atom: RecoilState<T>) => {
  const [state, setState] = useRecoilStateDefault(atom);

  return [
    state,
    useCallback(
      (valOrUpdater: T | DraftFunction<T>) =>
        setState(
          typeof valOrUpdater === 'function'
            ? produce(valOrUpdater as DraftFunction<T>)
            : (valOrUpdater as T),
        ),
      [setState],
    ),
  ] as const;
};

export default useRecoilState;
