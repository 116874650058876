import { useCallback } from 'react';

import S3ManagerAPI from '~/apis/S3ManagerAPI';
import { getDecodedFileName } from '~/utils';

import { useCreateS3Url } from './useCreateS3Url';

const useS3Handler = () => {
  const createS3Url = useCreateS3Url();

  const getFileName = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, path: string) => {
      try {
        const file = event.target.files?.[0];

        if (!file) return;

        const { url } = await createS3Url({ key: path + file.name });

        await S3ManagerAPI.putObject(url, file);

        const fileName = getDecodedFileName(url);

        return fileName;
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    [createS3Url],
  );

  return getFileName;
};

export default useS3Handler;
