import { Device } from './Device';
import { User } from './User';

export enum SubscribeState {
  REGISTERED = 'REGISTERED', // 등록(시작 전)
  SUBSCRIBING = 'SUBSCRIBING', // 구독중
  COMPLETED = 'COMPLETED', // 종료
}

export enum SubscribeStateText {
  REGISTERED = 'LX 등록',
  SUBSCRIBING = '구독중',
  COMPLETED = '구독 종료',
}

export enum SubscribeType {
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTH = 'THREE_MONTH',
  HALF_YEAR = 'HALF_YEAR',
}

export enum SubscribeTypeText {
  ONE_MONTH = '1개월',
  THREE_MONTH = '3개월',
  HALF_YEAR = '6개월',
}

export class Subscribe {
  _id: string;
  deviceId: string;
  device?: Device;
  subscribeState: SubscribeState;
  subscribeType: SubscribeType;
  subscribeStartedAt?: Date;
  subscribeFinishedAt?: Date;
  subscribeStartUserId: string;
  subscribeStartUser?: User;
  subscribeRegisterAdminUserId: string;
  subscribeRegisterAdminUser?: User;
  createdAt: Date;
  updatedAt: Date;

  constructor(subscribe: Subscribe) {
    this._id = subscribe._id;
    this.deviceId = subscribe.deviceId;
    this.device = subscribe.device && new Device(subscribe.device);
    this.subscribeState = subscribe.subscribeState;
    this.subscribeType = subscribe.subscribeType;
    this.subscribeStartedAt =
      subscribe.subscribeStartedAt && new Date(subscribe.subscribeStartedAt);
    this.subscribeFinishedAt =
      subscribe.subscribeFinishedAt && new Date(subscribe.subscribeFinishedAt);
    this.subscribeStartUserId = subscribe.subscribeStartUserId;
    this.subscribeStartUser =
      subscribe.subscribeStartUser && new User(subscribe.subscribeStartUser);
    this.subscribeRegisterAdminUserId = subscribe.subscribeRegisterAdminUserId;
    this.subscribeRegisterAdminUser =
      subscribe.subscribeRegisterAdminUser && new User(subscribe.subscribeRegisterAdminUser);
    this.createdAt = new Date(subscribe.createdAt);
    this.updatedAt = new Date(subscribe.updatedAt);

    return this;
  }
}
