import styled from '@emotion/styled';
import { DatePicker, Image } from 'antd';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import DefaultProfile from '~/assets/svg/default_profile.svg';
import { FocusTimer } from '~/classes/FocusTimer';
import Pagination from '~/classes/Pagination';
import AreaChart from '~/components/Chart/AreaChart';
import DoughnutChart from '~/components/Chart/DoughnutChart';
import Label from '~/components/Chart/Label';
import FocusTimerStateLabel from '~/components/FocusTimerStateLabel';
import { Space } from '~/components/Space';
import Color from '~/constants/Color';
import { useGetFocusTimers } from '~/hooks/useFocusTimer';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Text12Bold, Text16Bold, Text20Bold } from '~/styles/Texts';
import { Center, Column, Row, SpaceBetween } from '~/styles/Wrappers';
import { getEfficiencyPercentages, getTimeline, secondsToFormattedTime } from '~/utils';

import { COLORS, CRITERIA, LABELS } from '../UserDetail';

const ChartWrapper = styled(Row)`
  width: fit-content;
  height: fit-content;
  background-color: ${Color.C232329};
  border-radius: 16px;
  padding: 16px;
`;

const FocusTimers = () => {
  const navigate = useNavigate();

  const columns: ColumnsType<FocusTimer> = [
    {
      key: 'picture',
      title: '프로필',
      dataIndex: 'user',
      render: (user) => (
        <Image
          style={{ borderRadius: 50 }}
          src={user?.picture}
          width={40}
          height={40}
          fallback={DefaultProfile}
        />
      ),
    },
    {
      key: 'reportName',
      title: '리포트 이름',
      dataIndex: 'user',
      render: (user) => <>{user?.reportName || ''}</>,
    },
    {
      key: 'realname',
      title: '이름',
      dataIndex: 'user',
      render: (user) => <>{user?.realname || ''}</>,
    },
    {
      key: 'affiliation',
      title: '소속',
      dataIndex: 'user',
      render: (user) => <>{user?.affiliation || ''}</>,
    },
    {
      key: 'goal',
      title: '목표',
      dataIndex: 'goal',
      render: (goal) => <>{goal?.goal || ''}</>,
    },
    {
      key: 'time',
      title: '공부 시간',
      dataIndex: 'time',
      render: (time) => <>{secondsToFormattedTime(time)}</>,
    },
    {
      key: 'focusTimerState',
      title: '상태',
      dataIndex: 'focusTimerState',
      render: (focusTimerState, focusTimer) => (
        <FocusTimerStateLabel
          focusTimerState={focusTimerState}
          isExpired={focusTimer.isExpired()}
        />
      ),
    },
    {
      key: 'efficiency',
      title: '평균 두뇌가동률',
      dataIndex: 'efficiency',
      render: (efficiency, focusTimer) => <>{focusTimer.withBand ? `${efficiency}%` : '-'}</>,
    },
    {
      key: 'startedAt',
      title: '시작 시간',
      dataIndex: 'startedAt',
      render: (startedAt) => {
        return <>{format(startedAt, 'y. M. d. HH:mm')}</>;
      },
    },
    {
      key: 'completedAt',
      title: '종료 시간',
      dataIndex: 'completedAt',
      render: (completedAt) => {
        return completedAt ? <>{format(completedAt, 'y. M. d. HH:mm')}</> : <></>;
      },
    },
    {
      key: 'focusTimer',
      title: 'AI 두뇌 분석',
      dataIndex: '_id',
      render: (_id, focusTimer) =>
        focusTimer.withBand && (
          <ChartWrapper>
            <Column alignItems="center">
              <Center style={{ width: 140, height: 140 }}>
                <DoughnutChart
                  labels={LABELS}
                  colors={COLORS}
                  data={getEfficiencyPercentages([
                    focusTimer.efficiencyVHCount,
                    focusTimer.efficiencyHCount,
                    focusTimer.efficiencyMCount,
                    focusTimer.efficiencyLCount,
                  ])}
                />
                <Center style={{ position: 'absolute', flexDirection: 'column' }}>
                  <Text12Bold color={Color.CEFEFEF}>평균 두뇌 가동률</Text12Bold>
                  <Text16Bold color={Color.CEFEFEF}>{focusTimer.efficiency}%</Text16Bold>
                </Center>
              </Center>
              <Space height={10} />

              <Label
                labels={LABELS}
                colors={COLORS}
                data={getEfficiencyPercentages([
                  focusTimer.efficiencyVHCount,
                  focusTimer.efficiencyHCount,
                  focusTimer.efficiencyMCount,
                  focusTimer.efficiencyLCount,
                ])}
              />
            </Column>
            <Space width={10} />

            <Center>
              <AreaChart
                labels={LABELS}
                colors={COLORS}
                timeline={getTimeline(focusTimer.time)}
                criteria={CRITERIA}
                data={focusTimer.efficiencies}
              />
            </Center>
          </ChartWrapper>
        ),
    },
  ];

  const { page, limit } = queryString.parse(location.search);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik<{
    keyword: string;
    startDate?: Date;
    endDate?: Date;
  }>({
    initialValues: {
      keyword: '',
      startDate: undefined,
      endDate: undefined,
    },
    validationSchema: Yup.object().shape({
      keyword: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));

  const { data = new Pagination<FocusTimer>(), refetch } = useGetFocusTimers({
    ...pagination,
    keyword: values.keyword,
    startDate: values.startDate,
    endDate: values.endDate,
  });

  useEffect(() => {
    navigate(`/focus-timers?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Row>
          <Text20Bold>포커스타이머</Text20Bold>
          <Space width={12} />
        </Row>
        <SpaceBetween>
          <DatePicker.RangePicker
            onChange={(date) => {
              console.log(date);

              if (date?.[0] && date?.[1]) {
                setFieldValue('startDate', startOfDay(new Date(date[0].toISOString())));
                setFieldValue('endDate', endOfDay(new Date(date[1].toISOString())));
              } else {
                setFieldValue('startDate', undefined);
                setFieldValue('endDate', undefined);
              }
            }}
          />
          <Search
            name="keyword"
            style={{ width: 200 }}
            placeholder="검색어를 입력하세요"
            value={values.keyword}
            onChange={handleChange}
            onSearch={() => handleSubmit()}
            allowClear
          />
        </SpaceBetween>
      </SpaceBetween>
      <Space height={12} />
      <Table
        style={{ cursor: 'pointer', overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(focusTimer) => ({
          onClick: () => navigate(`/users/${focusTimer.user?._id}`),
        })}
      />
    </Column>
  );
};

export default FocusTimers;
