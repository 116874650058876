import Pagination from '~/classes/Pagination';
import { User } from '~/classes/User';
import { ProfileDto } from '~/classes/dto/ProfileDto';
import { SearchUsersDto } from '~/classes/dto/SearchUserDto';
import axios from '~/helper/axios';

const getUsers = async (searchUsersDto: SearchUsersDto): Promise<Pagination<User>> => {
  const { data: users } = await axios<Pagination<User>>({
    url: '/user/users',
    method: 'get',
    params: searchUsersDto,
  });

  return new Pagination(users, User);
};

const getUser = async (id: string): Promise<User> => {
  const { data: user } = await axios<User>({
    url: `/user/users/${id}`,
    method: 'get',
    params: { id },
  });

  return new User(user);
};

const updateUserProfile = async (profileDto: ProfileDto): Promise<void> => {
  return await axios({
    url: '/user/profile-admin',
    method: 'patch',
    data: profileDto,
  });
};

export default { getUsers, getUser, updateUserProfile };
