import { Token } from '~/classes/Token';
import { User } from '~/classes/User';
import { SignInDto } from '~/classes/dto/SignInDto';
import axios from '~/helper/axios';

const signIn = (signInDto: SignInDto) => {
  return axios<{ user: User; token: Token }>({
    url: '/auth/firebase/sign',
    method: 'post',
    data: signInDto,
  });
};

const load = () => {
  return axios<User>({ url: '/auth/load', method: 'get' });
};

export default { signIn, load };
