import { Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { ProfileDto } from '~/classes/dto/ProfileDto';
import { useUpdateUserProfile } from '~/hooks/useUser';

interface Props {
  userId: string;
  memo: string;
  updateMemoModalOpen: boolean;
  setUpdateMemoModalOpen: (updateMemoModalOpen: boolean) => void;
}

const UpdateMemoModal = ({ userId, memo, updateMemoModalOpen, setUpdateMemoModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const updateUserProfile = useUpdateUserProfile();

  const { values, handleChange, handleSubmit } = useFormik<ProfileDto>({
    initialValues: {
      userId,
      memo,
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().required(),
      memo: Yup.string(),
    }),
    onSubmit: async (data: ProfileDto) => {
      await updateUserProfile(data);

      setIsConfirmLoading(false);
      setUpdateMemoModalOpen(false);
    },
  });

  return (
    <Modal
      title="특이사항 수정"
      open={updateMemoModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setUpdateMemoModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="특이사항">
          <TextArea
            name="memo"
            value={values.memo}
            placeholder="특이사항을 입력하세요"
            onChange={handleChange}
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateMemoModal;
