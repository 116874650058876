import { EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Checkbox, Image, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { differenceInMinutes, differenceInSeconds, differenceInYears, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import LambdaApi from '~/apis/LambdaApi';
import DefaultProfile from '~/assets/svg/default_profile.svg';
import { FocusTimer } from '~/classes/FocusTimer';
import { Goal } from '~/classes/Goal';
import Pagination from '~/classes/Pagination';
import { Role } from '~/classes/User';
import { UserGoals } from '~/classes/UserGoals';
import AreaChart from '~/components/Chart/AreaChart';
import DoughnutChart from '~/components/Chart/DoughnutChart';
import Label from '~/components/Chart/Label';
import FocusTimerStateLabel from '~/components/FocusTimerStateLabel';
import { Space } from '~/components/Space';
import Color from '~/constants/Color';
import useRecoilValue from '~/hooks/recoilApis/useRecoilValue';
import { useGetUserGoals } from '~/hooks/useGoal';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetUser, useUpdateUserProfile } from '~/hooks/useUser';
import authState from '~/recoil/auth/atom';
import { Text12Bold, Text16Bold } from '~/styles/Texts';
import { Center, Column, RadiusContainer, Row } from '~/styles/Wrappers';
import {
  getEfficiencyPercentages,
  getTimeline,
  handleCopyClipBoard,
  secondsToFormattedTime,
  secondsToString,
} from '~/utils';

import CreatePointModal from './CreatePointModal';
import UpdateMemoModal from './UpdateMemoModal';
import UpdateRealnameModal from './UpdateRealnameModal';

const ChartWrapper = styled(Row)`
  width: fit-content;
  height: fit-content;
  background-color: ${Color.C232329};
  border-radius: 16px;
  padding: 16px;
`;

export const LABELS = ['매우높음', '높음', '보통', '낮음'];
export const COLORS = [Color.C01F0C8, Color.C4EA5B8, Color.C4760A0, Color.C59518D];
export const CRITERIA = [0.3, 0.47, 0.63, 1];

const UserDetail = () => {
  const expandedGoals = (goals: Goal[]) => {
    const goalsColumns = [
      {
        key: 'goal',
        title: '목표',
        dataIndex: 'goal',
      },
      {
        key: 'focusTimers',
        title: '공부 시간',
        dataIndex: 'focusTimers',
        render: (focusTimers) => {
          const focusTime = focusTimers.reduce((a, b) => a + b.time, 0);

          return <>{secondsToFormattedTime(focusTime)}</>;
        },
      },
      {
        key: 'focusTimers',
        title: '평균 두뇌가동률',
        dataIndex: 'focusTimers',
        render: (focusTimers: FocusTimer[]) => {
          const focusTime = focusTimers.reduce((a, b) => a + b.time, 0);
          const efficiencySum = focusTimers.reduce((a, b) => a + b.time * b.efficiency, 0);
          const efficiency = efficiencySum / focusTime ? Math.floor(efficiencySum / focusTime) : 0;
          const withBand = focusTimers.some((focusTimer) => focusTimer.withBand);

          return <>{withBand ? `${efficiency}%` : '-'}</>;
        },
      },
    ];

    const expandedFocusTimers = (focusTimers: FocusTimer[]) => {
      const focusTimersColumns: ColumnsType<FocusTimer> = [
        {
          key: 'time',
          title: '공부 시간',
          dataIndex: 'time',
          render: (time) => {
            return <>{secondsToFormattedTime(time)}</>;
          },
        },
        {
          key: 'efficiency',
          title: '평균 두뇌가동률',
          dataIndex: 'efficiency',
          render: (efficiency, focusTimer) => <>{focusTimer.withBand ? `${efficiency}%` : '-'}</>,
        },
        {
          key: 'focusTimerState',
          title: '상태',
          dataIndex: 'focusTimerState',
          render: (focusTimerState, focusTimer) => (
            <FocusTimerStateLabel
              focusTimerState={focusTimerState}
              isExpired={focusTimer.isExpired()}
            />
          ),
        },
        {
          key: 'startedAt',
          title: '시작 시간 ~ 종료 시간',
          dataIndex: 'startedAt',
          render: (startedAt, record) => {
            return (
              <>
                {format(startedAt, 'HH:mm')} ~{' '}
                {format(record.completedAt || record.updatedAt, 'HH:mm')}
                <br />
                {`총 ${differenceInMinutes(record.completedAt || record.updatedAt, startedAt)}분`}
                <br />
                Running: {`${secondsToString(record.time, ':', true, false)}`}
                <br />
                Pause: {`${secondsToString(record.pauseTime, ':', true, false)}`}
              </>
            );
          },
        },

        {
          key: 'btn',
          title: '분석',
          dataIndex: '_id',
          render: (_id) => {
            return (
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const result = await LambdaApi.getTimerState(_id, userId);
                  if (typeof result === 'string') {
                    toast.success(result);
                  } else {
                    toast.error('분석에 실패했습니다.');
                  }
                }}
              >
                분석
              </Button>
            );
          },
        },

        {
          key: 'focusTimer',
          title: 'AI 두뇌 분석',
          dataIndex: '_id',
          width: 200,
          render: (_id, focusTimer) =>
            focusTimer.withBand && (
              <ChartWrapper>
                <Column alignItems="center">
                  <Center style={{ width: 140, height: 140 }}>
                    <DoughnutChart
                      labels={LABELS}
                      colors={COLORS}
                      data={getEfficiencyPercentages([
                        focusTimer.efficiencyVHCount,
                        focusTimer.efficiencyHCount,
                        focusTimer.efficiencyMCount,
                        focusTimer.efficiencyLCount,
                      ])}
                    />
                    <Center style={{ position: 'absolute', flexDirection: 'column' }}>
                      <Text12Bold color={Color.CEFEFEF}>평균 두뇌 가동률</Text12Bold>
                      <Text16Bold color={Color.CEFEFEF}>{focusTimer.efficiency}%</Text16Bold>
                    </Center>
                  </Center>
                  <Space height={10} />

                  <Label
                    labels={LABELS}
                    colors={COLORS}
                    data={getEfficiencyPercentages([
                      focusTimer.efficiencyVHCount,
                      focusTimer.efficiencyHCount,
                      focusTimer.efficiencyMCount,
                      focusTimer.efficiencyLCount,
                    ])}
                  />
                </Column>
                <Space width={10} />

                <Center>
                  <AreaChart
                    labels={LABELS}
                    colors={COLORS}
                    timeline={getTimeline(
                      differenceInSeconds(
                        focusTimer.completedAt || focusTimer.startedAt,
                        focusTimer.startedAt,
                      ),
                    )}
                    criteria={CRITERIA}
                    data={focusTimer.efficiencies}
                  />
                </Center>
              </ChartWrapper>
            ),
        },
        {
          key: 'diaryImages',
          title: '다이어리 이미지',
          dataIndex: 'diaryImages',
          width: 200,
          render: (diaryImages) =>
            diaryImages.length > 0 ? (
              diaryImages.map((diaryImage) => (
                <Image width={100} key={diaryImage} src={diaryImage} />
              ))
            ) : (
              <>-</>
            ),
        },
        {
          key: 'assessment',
          title: '평가',
          dataIndex: 'assessment',
          width: 100,
          render: (assessment) => <>{assessment || '-'}</>,
        },
      ];

      return (
        <Table
          style={{ cursor: 'pointer' }}
          rowKey={({ _id }) => _id}
          columns={focusTimersColumns}
          dataSource={focusTimers}
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleCopyClipBoard(record._id),
          })}
        />
      );
    };

    return (
      <Table
        style={{ cursor: 'pointer' }}
        rowKey={({ _id }) => _id}
        columns={goalsColumns}
        dataSource={goals}
        pagination={false}
        expandable={{
          expandedRowRender: (goal) => expandedFocusTimers(goal.focusTimers),
          defaultExpandAllRows: true,
          expandRowByClick: true,
        }}
      />
    );
  };

  const columns = [
    {
      key: '_id',
      title: '날짜',
      dataIndex: '_id',
      render: (_id) => <>{new Date(_id).toLocaleDateString()}</>,
      sorter: (a, b) => new Date(a._id).getTime() - new Date(b._id).getTime(),
    },
    {
      key: 'goals',
      title: '하루 공부시간',
      dataIndex: 'goals',
      render: (goals: Goal[]) => {
        const focusTime = goals.reduce((a, b) => a + b.getFocusTime(), 0);

        return <>{secondsToFormattedTime(focusTime)}</>;
      },
      sorter: (a, b) => {
        const aFocusTime = a.goals.reduce((a, b) => a + b.getFocusTime(), 0);
        const bFocusTime = b.goals.reduce((a, b) => a + b.getFocusTime(), 0);

        return aFocusTime < bFocusTime ? -1 : aFocusTime > bFocusTime ? 1 : 0;
      },
    },
    {
      key: 'goals',
      title: '하루 평균 두뇌가동률',
      dataIndex: 'goals',
      render: (goals) => {
        const focusTime = goals.reduce((a, b) => a + b.getFocusTime(), 0);
        const efficiencySum = goals.reduce((a, b) => a + b.getEfficiency(), 0);
        const efficiency = efficiencySum / focusTime ? Math.floor(efficiencySum / focusTime) : 0;
        const withBand = goals.some((goal) =>
          goal.focusTimers.length > 0 ? goal.getWithBand() : false,
        );

        return <>{withBand ? `${efficiency}%` : '알 수 없음'}</>;
      },
      sorter: (a, b) => {
        const aFocusTime = a.goals.reduce((a, b) => a + b.getFocusTime(), 0);
        const bFocusTime = b.goals.reduce((a, b) => a + b.getFocusTime(), 0);

        const aEfficiencySum = a.goals.reduce((a, b) => a + b.getEfficiency(), 0);
        const bEfficiencySum = b.goals.reduce((a, b) => a + b.getEfficiency(), 0);

        const aEfficiency =
          aEfficiencySum / aFocusTime ? Math.floor(aEfficiencySum / aFocusTime) : 0;
        const bEfficiency =
          bEfficiencySum / bFocusTime ? Math.floor(bEfficiencySum / bFocusTime) : 0;

        return aEfficiency < bEfficiency ? -1 : aEfficiency > bEfficiency ? 1 : 0;
      },
    },
  ];
  const { me } = useRecoilValue(authState);

  const { userId = me._id } = useParams();

  const { data: user, refetch: refetchUser } = useGetUser(userId);
  const [pagination, setPage, setLimit] = usePaginationRequest();
  const { data: userGoals = new Pagination<UserGoals>(), refetch: refetchUserGoals } =
    useGetUserGoals({
      ...pagination,
      userId,
    });

  const [updateRealnameModalOpen, setUpdateRealnameModalOpen] = useState(false);
  const [updateMemoModalOpen, setUpdateMemoModalOpen] = useState(false);
  const [createPointModalOpen, setCreatePointModalOpen] = useState(false);
  const [coachChecked, setCoachChecked] = useState(user?.roles.includes(Role.COACH));

  const updateProfile = useUpdateUserProfile();

  const handleChange = async () => {
    let newRoles = user?.roles.slice();

    if (!coachChecked) {
      newRoles?.push(Role.COACH);
    } else {
      newRoles = newRoles?.filter((role) => role !== Role.COACH);
    }

    await updateProfile({ userId, roles: newRoles });

    setCoachChecked(!coachChecked);
  };

  useEffect(() => {
    refetchUser();
  }, [refetchUser, updateRealnameModalOpen, updateMemoModalOpen, coachChecked]);

  useEffect(() => {
    refetchUserGoals();
  }, [refetchUserGoals]);

  return (
    <Column flex={1}>
      <RadiusContainer backgroundColor={Color.C01F0C880}>
        <Row alignItems="center">
          <Image
            style={{ borderRadius: 50 }}
            src={user?.picture}
            width={40}
            height={40}
            fallback={DefaultProfile}
          />
          <Space width={20} />

          <Column>
            <Row alignItems="center">
              <Text16Bold>리포트 이름: {user?.reportName}</Text16Bold>
              <Space width={36} />

              <Text16Bold>이름: {user?.realname ? user.realname : '없음'}</Text16Bold>
              <EditOutlined onClick={() => setUpdateRealnameModalOpen(true)} />
              <Space width={36} />

              <Text16Bold>이메일: {user?.email}</Text16Bold>
              <Space width={36} />

              <Text16Bold>소속: {user?.affiliation}</Text16Bold>
              <Space width={36} />

              {user?.grade ? (
                <>
                  <Text16Bold>학년: {user.grade}학년</Text16Bold>
                  <Space width={36} />
                </>
              ) : (
                <></>
              )}

              {user?.birthday ? (
                <>
                  <Text16Bold>
                    나이: {differenceInYears(new Date(), new Date(user.birthday))}세
                  </Text16Bold>
                  <Space width={36} />
                </>
              ) : (
                <></>
              )}

              {user?.platform ? (
                <>
                  <Text16Bold>
                    OS: {user.platform.OS} / appVersion: {user.platform.appVersion}
                  </Text16Bold>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Space height={8} />

            <Row alignItems="center">
              <Text16Bold>디바이스 이름: {user?.device?.deviceName || '없음'}</Text16Bold>
              <Space width={36} />

              <Text16Bold>생성일: {user?.createdAt.toLocaleDateString()}</Text16Bold>
              <Space width={36} />

              <Text16Bold>
                특이사항:{' '}
                {user?.memo
                  ? user.memo.length > 10
                    ? `${user.memo.substring(0, 10)}...`
                    : user.memo
                  : '없음'}
              </Text16Bold>
              <EditOutlined onClick={() => setUpdateMemoModalOpen(true)} />
              <Space width={36} />

              <Checkbox
                checked={coachChecked}
                defaultChecked={coachChecked}
                disabled={!me?.roles.includes(Role.ADMIN)}
                onChange={handleChange}
              >
                <Text16Bold>코치</Text16Bold>
              </Checkbox>
              <Space width={36} />

              <Button onClick={() => setCreatePointModalOpen(true)}>포인트 부여</Button>
            </Row>
          </Column>
        </Row>
      </RadiusContainer>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={userGoals.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: userGoals.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        expandable={{
          expandedRowRender: (dailyData) => expandedGoals(dailyData.goals),
          defaultExpandAllRows: true,
          expandRowByClick: true,
        }}
      />

      <UpdateMemoModal
        userId={user?._id || ''}
        memo={user?.memo || ''}
        updateMemoModalOpen={updateMemoModalOpen}
        setUpdateMemoModalOpen={setUpdateMemoModalOpen}
      />
      <UpdateRealnameModal
        userId={user?._id || ''}
        realname={user?.realname || ''}
        updateRealnameModalOpen={updateRealnameModalOpen}
        setUpdateRealnameModalOpen={setUpdateRealnameModalOpen}
      />
      <CreatePointModal
        userId={user?._id || ''}
        createPointModalOpen={createPointModalOpen}
        setCreatePointModalOpen={setCreatePointModalOpen}
      />
    </Column>
  );
};

export default UserDetail;
