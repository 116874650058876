import { useQuery } from '@tanstack/react-query';

import FocusTimerApi from '~/apis/FocusTimerApi';
import { SearchFocusTimerDto } from '~/classes/dto/FocusTimerDto';

export const useGetFocusTimers = (searchFocusTimerDto: SearchFocusTimerDto) => {
  const query = useQuery({
    queryKey: ['focusTimers', searchFocusTimerDto],
    queryFn: () => FocusTimerApi.getFocusTimers(searchFocusTimerDto),
  });

  return query;
};
