import { Device } from '~/classes/Device';
import Pagination from '~/classes/Pagination';
import { CreateDeviceDto, GetDeviceDto } from '~/classes/dto/DeviceDto';
import axios from '~/helper/axios';

const createDevice = async (data: CreateDeviceDto): Promise<void> => {
  return await axios({
    url: '/device',
    method: 'post',
    data,
  });
};

const getDevices = async (params: GetDeviceDto): Promise<Pagination<Device>> => {
  const { data: devices } = await axios<Pagination<Device>>({
    url: '/device',
    method: 'get',
    params,
  });

  return new Pagination(devices, Device);
};

export default { createDevice, getDevices };
