import { useCallback, useState } from 'react';

import PaginationRequest from '~/classes/PaginationRequest';

const usePaginationRequest = (
  page = 1,
  limit = 10,
): [PaginationRequest, (page: number) => void, (limit: number) => void] => {
  const [pagination, setPagination] = useState(new PaginationRequest({ page, limit }));

  const setPage = useCallback((page: number) => {
    setPagination((pagination) => new PaginationRequest({ page, limit: pagination.limit }));
  }, []);

  const setLimit = useCallback((limit: number) => {
    setPagination((pagination) => new PaginationRequest({ page: pagination.page, limit }));
  }, []);

  return [pagination, setPage, setLimit];
};

export default usePaginationRequest;
