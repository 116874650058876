export enum ContentCategory {
  FOCUS = 'FOCUS',
  STRESS = 'STRESS',
  BURN_OUT = 'BURN_OUT',
  ANXIETY = 'ANXIETY',
  SLEEP = 'SLEEP',
}

export enum ContentCategoryText {
  FOCUS = '집중',
  STRESS = '스트레스',
  BURN_OUT = '번아웃',
  ANXIETY = '불안',
  SLEEP = '수면',
}

export enum ContentType {
  MEDITATION_GUIDE = 'MEDITATION_GUIDE',
  SOUNDSCAPE = 'SOUNDSCAPE',
  MUSIC = 'MUSIC',
  VOICE = 'VOICE',
  BINAURAL_BEATS = 'BINAURAL_BEATS',
}

export enum ContentTypeText {
  MEDITATION_GUIDE = '명상 가이드',
  SOUNDSCAPE = '사운드스케이프',
  MUSIC = '음악',
  VOICE = 'Voice',
  BINAURAL_BEATS = '바이노럴 비트',
}

export class Content {
  _id: string;
  contentCategory: ContentCategory;
  title: string;
  contentType: ContentType;
  image: string;
  audio: string;
  duration: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(content: Content) {
    this._id = content._id;
    this.contentCategory = content.contentCategory;
    this.title = content.title;
    this.contentType = content.contentType;
    this.image = content.image;
    this.audio = content.audio;
    this.duration = content.duration;
    this.createdAt = new Date(content.createdAt);
    this.updatedAt = new Date(content.updatedAt);
  }
}
