import { Navigate, Outlet } from 'react-router-dom';

import Layout from './components/Layout';
import Path from './constants/Path';
import { Flex } from './styles/Wrappers';

interface Props {
  isValid: boolean;
  withLayout: boolean;
  redirectPath: ValueOf<typeof Path>;
}

const ProtectedRoute = ({ isValid, withLayout, redirectPath }: Props) => {
  if (!isValid) return <Navigate to={redirectPath} replace />;

  return withLayout ? (
    <Layout />
  ) : (
    <Flex style={{ width: '100vw', height: '100vh' }}>
      <Outlet />
    </Flex>
  );
};

export default ProtectedRoute;
