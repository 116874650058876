import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import DeviceApi from '~/apis/DeviceApi';
import { GetDeviceDto } from '~/classes/dto/DeviceDto';

export const useCreateDevice = () => {
  const { mutateAsync } = useMutation({
    mutationFn: DeviceApi.createDevice,
    onError: () => {
      toast.error('이미 존재하는 디바이스 이름입니다!');
    },
  });

  return mutateAsync;
};

export const useGetDevices = (getDeviceDto: GetDeviceDto) => {
  const query = useQuery({
    queryKey: ['getDeviceDto', getDeviceDto.page, getDeviceDto.limit],
    queryFn: () => DeviceApi.getDevices(getDeviceDto),
  });

  return query;
};
