import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Path from './constants/Path';
import useRecoilValue from './hooks/recoilApis/useRecoilValue';
import useLoad from './hooks/useLoad';
import Contents from './pages/Contents';
import Dashboard from './pages/Dashboard';
import DeviceSubscribes from './pages/DeviceSubscribes';
import Devices from './pages/Devices';
import FocusTimers from './pages/FocusTimers';
import Loading from './pages/Loading';
import Points from './pages/Points';
import Refunds from './pages/Refunds';
import Signin from './pages/SignIn';
import Subscribes from './pages/Subscribes';
import UserDetail from './pages/UserDetail';
import Users from './pages/Users';
import authState from './recoil/auth/atom';

const App = () => {
  const { isLoading, isSignIn } = useRecoilValue(authState);

  useLoad();

  if (isLoading) return <Loading />;

  return (
    <Router>
      <Routes>
        <Route
          element={
            <ProtectedRoute isValid={isSignIn} withLayout={true} redirectPath={Path.SIGNIN} />
          }
        >
          <Route path={Path.DASHBOARD} element={<Dashboard />} />
          <Route path={Path.USERS} element={<Users />} />
          <Route path={Path.USER_DETAIL} element={<UserDetail />} />
          <Route path={Path.FOCUS_TIMERS} element={<FocusTimers />} />
          <Route path={Path.DEVICES} element={<Devices />} />
          <Route path={Path.SUBSCRIBES} element={<Subscribes />} />
          <Route path={Path.DEVICE_SUBSCRIBES} element={<DeviceSubscribes />} />
          <Route path={Path.REFUNDS} element={<Refunds />} />
          <Route path={Path.POINTS} element={<Points />} />
          <Route path={Path.CONTENTS} element={<Contents />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isValid={!isSignIn} withLayout={false} redirectPath={Path.DASHBOARD} />
          }
        >
          <Route path={Path.SIGNIN} element={<Signin />} />
        </Route>
        <Route path={'/*'} element={<Navigate to={Path.SIGNIN} />} />
      </Routes>
    </Router>
  );
};

export default App;
