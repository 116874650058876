import { Form, Input, Modal } from 'antd';
import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { useUpdateSubscribe } from '~/hooks/useSubscribe';

interface Props {
  subscribeId: string;
  subscribeFinishedAt: Date;
  addSubscribeModalOpen: boolean;
  setAddSubscribeModalOpen: (addSubscribeModalOpen: boolean) => void;
}

const AddSubscribeModal = ({
  subscribeId,
  subscribeFinishedAt,
  addSubscribeModalOpen,
  setAddSubscribeModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const updateSubscribe = useUpdateSubscribe();

  const { values, handleChange, handleSubmit } = useFormik<{ day: number }>({
    initialValues: {
      day: 0,
    },
    validationSchema: Yup.object().shape({
      day: Yup.number().required(),
    }),
    onSubmit: async (data: { day: number }) => {
      await updateSubscribe({
        subscribeId,
        subscribeFinishedAt: addDays(subscribeFinishedAt, data.day),
      });

      setIsConfirmLoading(false);
      setAddSubscribeModalOpen(false);
    },
  });

  return (
    <Modal
      title="구독일 추가"
      open={addSubscribeModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setAddSubscribeModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="추가할 구독일 수">
          <Input type="number" name="day" value={values.day} onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSubscribeModal;
