import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import UserApi from '~/apis/UserApi';
import { SearchUsersDto } from '~/classes/dto/SearchUserDto';
import authState from '~/recoil/auth/atom';

import useRecoilState from './recoilApis/useRecoilState';

export const useGetUser = (id: string) => {
  const query = useQuery({
    queryKey: ['id', id],
    queryFn: () => UserApi.getUser(id),
  });

  return query;
};

export const useGetUsers = (searchUsersDto: SearchUsersDto) => {
  const query = useQuery({
    queryKey: ['users', searchUsersDto],
    queryFn: () => UserApi.getUsers(searchUsersDto),
  });

  return query;
};

export const useUpdateUserProfile = () => {
  const [auth, setAuth] = useRecoilState(authState);

  const { mutateAsync, variables } = useMutation({
    mutationFn: UserApi.updateUserProfile,
    onSuccess: () => {
      if (auth.me._id === variables?.userId) {
        setAuth((draft) => {
          draft.me = {
            ...draft.me,
            ...variables,
          };
        });
      }
    },
    onError: () => {
      toast.error('특이사항 저장에 실패했습니다!');
    },
  });

  return mutateAsync;
};
