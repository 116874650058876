import { Modal } from 'antd';
import { useState } from 'react';

import { useDeleteContent, useGetContent } from '~/hooks/useContent';
import { Text16 } from '~/styles/Texts';

interface Props {
  contentId: string;
  deleteModalOpen: boolean;
  setDeleteModalOpen: (deleteModalOpen: boolean) => void;
}

const DeleteModal = ({ contentId, deleteModalOpen, setDeleteModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { data: content } = useGetContent(contentId);
  const deleteContent = useDeleteContent();

  const handleDelete = async () => {
    setIsConfirmLoading(true);

    await deleteContent(contentId);

    setIsConfirmLoading(false);
    setDeleteModalOpen(false);
  };

  return (
    <Modal
      title="콘텐츠 삭제"
      open={deleteModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleDelete()}
      onCancel={() => setDeleteModalOpen(false)}
    >
      <Text16>타이틀: {content?.title}</Text16>
    </Modal>
  );
};

export default DeleteModal;
