export default class Pagination<T> {
  limit: number; // 한번에 가져오는 갯수
  count: number; // 총 데이터의 갯수
  pages: number; // 페이지 수
  data: T[];

  constructor(
    pagination: Pagination<T> = {
      limit: 10,
      count: 0,
      pages: 1,
      data: [],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Class?: any,
  ) {
    this.limit = pagination.limit || 10;
    this.count = pagination.count || 0;
    this.pages = pagination.pages || 1;
    this.data = (pagination.data || []).map((data) => new Class(data));
  }
}
