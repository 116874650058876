import Axios from 'axios';

import axios from '~/helper/axios';

const putObject = async (url: string, file: File): Promise<void> => {
  await Axios.put(url, file, {
    headers: { 'Content-Type': 'application/octet-stream' },
  });
};

const createS3Url = async (data: { key: string }) => {
  const { data: url } = await axios<{ url: string }>({
    url: '/s3-manager/upload-url',
    method: 'post',
    data,
  });

  return url;
};

export default { putObject, createS3Url };
