import { atom } from 'recoil';

import { Affiliation, Gender, User } from '~/classes/User';
import { isSignIn } from '~/utils';

interface AuthState {
  isLoading: boolean;
  isSignIn: boolean;
  me: User;
}

export const initialState: AuthState = {
  isLoading: false,
  isSignIn: isSignIn(),
  me: {
    _id: '',
    email: '',
    reportName: '',
    picture: '',
    affiliation: Affiliation.MIDDLE_SCHOOL,
    birthday: '',
    gender: Gender.FEMALE,
    providerIds: [],
    providers: [],
    roles: [],
    isPrivate: false,
    systemPush: false,
    deviceId: '',
    platform: {
      OS: '',
      appVersion: '',
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
};

const authState = atom<AuthState>({
  key: 'authState',
  default: initialState,
});

export default authState;
