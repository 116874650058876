import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import { Point } from '~/classes/Point';
import { PointLog } from '~/classes/PointLog';
import { PointDto } from '~/classes/dto/PointDto';
import axios from '~/helper/axios';

const createPoint = async (pointDto: PointDto): Promise<void> => {
  await axios<void>({
    url: '/point/admin',
    method: 'post',
    data: pointDto,
  });
};

const getPoints = async (params: PaginationRequest): Promise<Pagination<Point>> => {
  const { data } = await axios<Pagination<Point>>({
    url: '/point/admin',
    method: 'get',
    params,
  });

  return new Pagination(data, Point);
};

const getRefunds = async (params: PaginationRequest): Promise<Pagination<PointLog>> => {
  const { data } = await axios<Pagination<PointLog>>({
    url: '/point/admin/refund',
    method: 'get',
    params,
  });

  return new Pagination(data, PointLog);
};

const updateRefund = async (pointLogId: string): Promise<void> => {
  await axios<void>({
    url: `/point/admin/refund/${pointLogId}`,
    method: 'patch',
  });
};

export default { createPoint, getPoints, getRefunds, updateRefund };
