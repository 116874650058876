import Axios from 'axios';

const getTimerState = async (focusId: string, userId: string): Promise<string> => {
  const { data } = await Axios.post(
    'https://zj02gkaq2k.execute-api.ap-northeast-2.amazonaws.com/process/timer-state',
    { focusId, userId },
    { headers: { 'Content-Type': 'application/json' }, timeout: 30 * 1000 },
  );

  return data;
};

export default { getTimerState };
