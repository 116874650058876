import { Goal } from './Goal';

export class UserGoals {
  _id: string;
  goals: Goal[] = [];

  constructor(userGoals: UserGoals) {
    this._id = userGoals._id;
    this.goals = userGoals.goals.map((goal) => new Goal(goal));
  }
}
