import {
  ControlOutlined,
  DashboardOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  RestOutlined,
  TransactionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import SiderDefault from 'antd/es/layout/Sider';
import Menu from 'antd/es/menu';
import { NavLink, useLocation } from 'react-router-dom';

import Color from '~/constants/Color';
import Path from '~/constants/Path';

type MenuItem = {
  key: string;
  label: React.ReactNode;
  icon: React.ReactNode;
};

const items: MenuItem[] = [
  {
    key: Path.DASHBOARD,
    label: <NavLink to={Path.DASHBOARD}>대시보드</NavLink>,
    icon: <DashboardOutlined />,
  },
  {
    key: Path.USERS,
    label: <NavLink to={`${Path.USERS}?page=1&limit=10`}>유저</NavLink>,
    icon: <UserOutlined />,
  },
  {
    key: Path.FOCUS_TIMERS,
    label: <NavLink to={`${Path.FOCUS_TIMERS}?page=1&limit=10`}>포커스 타이머</NavLink>,
    icon: <FieldTimeOutlined />,
  },
  {
    key: Path.DEVICES,
    label: <NavLink to={`${Path.DEVICES}?page=1&limit=10`}>디바이스</NavLink>,
    icon: <ControlOutlined />,
  },
  {
    key: Path.SUBSCRIBES,
    label: <NavLink to={`${Path.SUBSCRIBES}?page=1&limit=10`}>구독</NavLink>,
    icon: <DollarOutlined />,
  },
  {
    key: Path.POINTS,
    label: <NavLink to={`${Path.POINTS}?page=1&limit=10`}>포인트</NavLink>,
    icon: <TransactionOutlined />,
  },
  {
    key: Path.REFUNDS,
    label: <NavLink to={`${Path.REFUNDS}?page=1&limit=10`}>환급</NavLink>,
    icon: <RestOutlined />,
  },
  {
    key: Path.CONTENTS,
    label: <NavLink to={Path.CONTENTS}>콘텐츠</NavLink>,
    icon: <HeartOutlined />,
  },
];

const Sider = () => {
  const location = useLocation();

  return (
    <SiderDefault style={{ backgroundColor: Color.CFFFFFF }} width="18%">
      <Menu selectedKeys={[location.pathname]} items={items} />
    </SiderDefault>
  );
};

export default Sider;
