import { Modal } from 'antd';
import { useState } from 'react';

import { useUpdateRefund } from '~/hooks/useRefund';
import { Text16Bold } from '~/styles/Texts';

interface Props {
  pointLogId: string;
  updateRefundModalOpen: boolean;
  setUpdateRefundModalOpen: (updateRefundModalOpen: boolean) => void;
}

const UpdateRefundModal = ({
  pointLogId,
  updateRefundModalOpen,
  setUpdateRefundModalOpen,
}: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const updateRefund = useUpdateRefund();

  const handleSubmit = async () => {
    await updateRefund(pointLogId);

    setIsConfirmLoading(false);
    setUpdateRefundModalOpen(false);
  };

  return (
    <Modal
      title="환급 수정"
      open={updateRefundModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setUpdateRefundModalOpen(false)}
    >
      <Text16Bold>환급 완료로 처리하겠습니까?</Text16Bold>
    </Modal>
  );
};

export default UpdateRefundModal;
