import { Layout as LayoutDefault } from 'antd';

import Content from './Content';
import Header from './Header';
import Sider from './Sider';

const Layout = () => {
  return (
    <LayoutDefault style={{ width: '100vw', height: '100vh' }}>
      <Header />
      <LayoutDefault>
        <Sider />
        <Content />
      </LayoutDefault>
    </LayoutDefault>
  );
};

export default Layout;
