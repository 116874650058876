import { startOfToday } from 'date-fns';

import { FocusTimer } from './FocusTimer';
import { User } from './User';

export class Goal {
  _id: string;
  userId: string;
  user?: User;
  goal: string;
  goalTime: number;
  planedAt: Date;
  focusTimerIds: string[];
  focusTimers: FocusTimer[];
  createdAt: Date;
  updatedAt: Date;

  getIsPlay() {
    return this.focusTimers.length !== 0;
  }

  getIsOutdate() {
    return !this.getIsPlay() && startOfToday() > this.planedAt;
  }

  getComplete() {
    return this.focusTimers.some(
      (focusTime) => focusTime.feedback1 === '초과달성' || focusTime.feedback1 === '달성',
    );
  }

  getFocusTime() {
    return this.focusTimers.reduce((a, b) => a + b.time, 0);
  }

  getEfficiency() {
    return this.focusTimers.reduce((a, b) => a + b.time * b.efficiency, 0);
  }

  getWithBand() {
    return this.focusTimers.some((focusTime) => focusTime.withBand);
  }

  getLastFocusTimer() {
    return this.focusTimers.length > 0 ? this.focusTimers[this.focusTimers.length - 1] : undefined;
  }

  constructor(goal: Goal) {
    this._id = goal._id;
    this.userId = goal.userId;
    this.user = goal.user && new User(goal.user);
    this.goal = goal.goal;
    this.goalTime = goal.goalTime;
    this.planedAt = new Date(goal.planedAt);
    this.focusTimerIds = goal.focusTimerIds || [];
    this.focusTimers =
      (goal.focusTimers && goal.focusTimers.map((focusTimer) => new FocusTimer(focusTimer))) || [];
    this.createdAt = new Date(goal.createdAt);
    this.updatedAt = new Date(goal.updatedAt);
  }
}
