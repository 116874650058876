import { useState } from 'react';

import { SortOrder } from '~/classes/PaginationRequest';
import { getNewSortingKeywords } from '~/utils';

const useHeaderCell = () => {
  const [sortingKeywords, setSortingKeywords] = useState<[string, SortOrder][]>([]);

  const onHeaderCell = (sortKey: string) => ({
    onClick: () => {
      const newSortingKeywords = getNewSortingKeywords(sortKey, sortingKeywords);

      setSortingKeywords(newSortingKeywords);
    },
  });

  return {
    sortingKeywords,
    onHeaderCell,
  };
};

export default useHeaderCell;
