import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Image, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';

import { Content, ContentCategory, ContentCategoryText, ContentTypeText } from '~/classes/Content';
import Pagination from '~/classes/Pagination';
import { Space } from '~/components/Space';
import { useGetContents } from '~/hooks/useContent';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Column, Row } from '~/styles/Wrappers';

import ContentModal from './ContentModal';
import DeleteModal from './DeleteModal';

const Contents = () => {
  const columns: ColumnsType<Content> = [
    { key: 'title', title: '타이틀', dataIndex: 'title' },
    {
      key: 'contentType',
      title: '콘텐츠 타입',
      dataIndex: 'contentType',
      render: (contentType) => <>{ContentTypeText[contentType]}</>,
    },
    {
      key: 'image',
      title: '이미지',
      dataIndex: 'image',
      render: (image) => (
        <Column>
          <Image
            style={{ width: 100 }}
            src={`${process.env.REACT_APP_S3_URL}${image}`}
            alt={image}
            preview={false}
          />
          <>{image.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'audio',
      title: '오디오',
      dataIndex: 'audio',
      render: (audio) => (
        <Column>
          <audio
            style={{ width: 220 }}
            src={`${process.env.REACT_APP_S3_URL}${audio}`}
            autoPlay={false}
            controls
          />
          <>{audio.split('/')[1]}</>
        </Column>
      ),
    },
    { key: 'duration', title: '오디오 길이', dataIndex: 'duration' },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
    {
      key: 'update',
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setContentId(record._id);
            setContentModalOpen(true);
          }}
        />
      ),
    },
    {
      key: 'delete',
      render: (_, record) => (
        <DeleteOutlined
          onClick={() => {
            setContentId(record._id);
            setDeleteModalOpen(true);
          }}
        />
      ),
    },
  ];

  const [contentCategory, setContentCategory] = useState('');

  const [contentId, setContentId] = useState<string>();
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { data = new Pagination<Content>(), refetch } = useGetContents({
    ...pagination,
    contentCategory,
  });

  useEffect(() => {
    refetch();
  }, [contentModalOpen, deleteModalOpen, refetch]);

  return (
    <Column flex={1}>
      <Row>
        <Select
          style={{ width: 120 }}
          className="contentCategory"
          value={contentCategory}
          onChange={(contentCategory) => setContentCategory(contentCategory)}
          options={[
            { value: '', label: '전체' },
            ...Object.values(ContentCategory).map((contentCategory) => ({
              value: contentCategory,
              label: ContentCategoryText[contentCategory],
            })),
          ]}
        />
        <Space width={12} />

        <PlusCircleOutlined
          onClick={() => {
            setContentId('');
            setContentModalOpen(true);
          }}
        />
      </Row>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 콘텐츠 ${total} 개`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <ContentModal
        contentId={contentId}
        contentModalOpen={contentModalOpen}
        setContentModalOpen={setContentModalOpen}
      />
      <DeleteModal
        contentId={contentId!}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </Column>
  );
};

export default Contents;
