import { useQuery } from '@tanstack/react-query';

import GoalApi from '~/apis/GoalApi';
import { UserGoalsDto } from '~/classes/dto/UserGoalsDto';

export const useGetUserGoals = (userGoalsDto: UserGoalsDto) => {
  const query = useQuery({
    queryKey: ['useGoalsDto', userGoalsDto],
    queryFn: () => GoalApi.getUserGoals(userGoalsDto),
  });

  return query;
};
