import { Content as ContentDefault } from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';

import Color from '~/constants/Color';

const Content = () => {
  return (
    <ContentDefault
      style={{
        display: 'flex',
        backgroundColor: Color.CFFFFFF,
        padding: 20,
        margin: 24,
        borderRadius: 20,
      }}
    >
      <Outlet />
    </ContentDefault>
  );
};

export default Content;
