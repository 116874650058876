import { Form, Input, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PointLogType, PointLogTypeLabel } from '~/classes/PointLog';
import { PointDto } from '~/classes/dto/PointDto';
import { useCreatePoint } from '~/hooks/useRefund';

interface Props {
  userId: string;
  createPointModalOpen: boolean;
  setCreatePointModalOpen: (createPointModalOpen: boolean) => void;
}

const CreatePointModal = ({ userId, createPointModalOpen, setCreatePointModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const createPoint = useCreatePoint();

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik<PointDto>({
    initialValues: {
      amount: 0,
      pointLogType: PointLogType.USER_JOIN,
      memo: '',
      userId,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required(),
      pointLogType: Yup.string().required(),
      memo: Yup.string(),
      userId: Yup.string().required(),
    }),
    onSubmit: async (data: PointDto) => {
      await createPoint(data);

      setIsConfirmLoading(false);
      setCreatePointModalOpen(false);
    },
  });

  useEffect(() => {
    setFieldValue('userId', userId);
  }, [setFieldValue, userId]);

  return (
    <Modal
      title="포인트 부여"
      open={createPointModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setCreatePointModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="포인트">
          <Input
            name="amount"
            value={values.amount}
            placeholder="부여할 포인트를 입력하세요"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="포인트 타입">
          <Select
            className="pointLogType"
            value={values.pointLogType}
            onChange={(pointLogType) => setFieldValue('pointLogType', pointLogType)}
            options={Object.values(PointLogType).map((pointLogType) => ({
              value: pointLogType,
              label: PointLogTypeLabel[pointLogType],
            }))}
          />
        </Form.Item>

        <Form.Item label="메모">
          <TextArea
            name="memo"
            value={values.memo}
            placeholder="메모를 입력하세요"
            onChange={handleChange}
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePointModal;
