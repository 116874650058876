import { ArrowUpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Card as CardDefault } from 'antd';

import { Device } from '~/classes/Device';
import Pagination from '~/classes/Pagination';
import { Subscribe } from '~/classes/Subscribe';
import { User } from '~/classes/User';
import { Space } from '~/components/Space';
import Color from '~/constants/Color';
import { useGetDevices } from '~/hooks/useDevice';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetSubscribes } from '~/hooks/useSubscribe';
import { useGetUsers } from '~/hooks/useUser';
import { Text20Bold } from '~/styles/Texts';
import { Center, SpaceBetween } from '~/styles/Wrappers';
import { getRecentUsers, getTodayUsers } from '~/utils';

const Card = styled(CardDefault)`
  width: 300px;
  background-color: ${Color.C01F0C880};
`;

const Dashboard = () => {
  const [pagination] = usePaginationRequest(1, 10000);
  const { data: usersData = new Pagination<User>() } = useGetUsers(pagination);
  const { data: devicesData = new Pagination<Device>() } = useGetDevices(pagination);
  const { data: subscribesData = new Pagination<Subscribe>() } = useGetSubscribes(pagination);

  return (
    <Center flex={1}>
      <SpaceBetween>
        <Card title="총 유저 수" bordered={false}>
          <Text20Bold>{usersData.count}</Text20Bold>
        </Card>
        <Space width={40} />

        <Card title="오늘 가입한 유저 수" bordered={false}>
          <Text20Bold>
            {getTodayUsers(usersData.data)} <ArrowUpOutlined />
          </Text20Bold>
        </Card>
        <Space width={40} />

        <Card title="최근 7일 이내 추가된 유저 수" bordered={false}>
          <Text20Bold>
            {getRecentUsers(usersData.data)} <ArrowUpOutlined />
          </Text20Bold>
        </Card>
      </SpaceBetween>
      <Space height={40} />

      <SpaceBetween>
        <Card title="총 디바이스 수" bordered={false}>
          <Text20Bold>{devicesData.count}</Text20Bold>
        </Card>
        <Space width={40} />

        <Card title="총 구독 수" bordered={false}>
          <Text20Bold>{subscribesData.count}</Text20Bold>
        </Card>
      </SpaceBetween>
    </Center>
  );
};

export default Dashboard;
